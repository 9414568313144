import React, { useCallback } from 'react';
import styled from 'styled-components';

import useContextMenu from '../../hooks/useContextMenu';
import ContextMenu from '../ContextMenu';

const DateOpm = styled.span`
  color: darkgrey;
`;
const Wrapper = styled.div`
  position: relative;
  border: ${({ hasContextMenu }) =>
    hasContextMenu ? '1px dashed #e85a1e' : '1px solid transparent'};
  padding: 0.125rem;
`;

const OudeOpmerking = ({
  datum,
  opmerking,
  bedelingId,
  lidId,
  setAanwezig,
}) => {
  const handleItemClick = (action) => {
    switch (action) {
      case 'copyText':
        navigator.clipboard.writeText(opmerking);
        break;
      case 'hideOne':
        setAanwezig({
          variables: {
            lidId,
            bedelingId,
            isOpmerkingVerborgen: true,
          },
        });
        break;
      case 'hideAll':
        break;
      case 'goTo':
        window.location.href = `/bedelingen/view/${bedelingId}`;
        break;
      default:
        break;
    }
  };
  const { isOpened, open, setPoints, ref, onItemClick } = useContextMenu(
    handleItemClick,
  );
  const handleContextMenu = useCallback((e) => {
    e.preventDefault();
    open();
    setPoints({
      x: e.pageX,
      y: e.pageY,
    });
  }, []);
  return (
    <Wrapper onContextMenu={handleContextMenu} hasContextMenu={isOpened}>
      <DateOpm>{new Date(datum).toLocaleDateString()}:</DateOpm> {opmerking}
      {isOpened && (
        <ContextMenu ref={ref}>
          <ul>
            <li onClick={onItemClick} data-action="copyText">
              Kopieer text
            </li>
            <li onClick={onItemClick} data-action="hideOne">
              Verberg opmerking van {new Date(datum).toLocaleDateString()}
            </li>
            {/* <li onClick={onItemClick} data-action="hideAll">
              Verberg alle opmerkingen van {lidName}
            </li> */}
            <li onClick={onItemClick} data-action="goTo">
              Ga naar bedeling van {new Date(datum).toLocaleDateString()}
            </li>
          </ul>
        </ContextMenu>
      )}
    </Wrapper>
  );
};

export default OudeOpmerking;
