import React from 'react';
import { useField } from 'formik';

import { Error, Input, Label, Wrapper } from '.';

const Checkbox = ({ name, label, required }) => {
  const [field, meta] = useField(name);
  return (
    <Wrapper>
      <Label htmlFor={name}>{label}</Label>
      <Input>
        <input
          type="checkbox"
          className={!field.value && required ? 'roderand' : undefined}
          checked={field.value}
          {...field}
        />
        {meta.error && meta.touched && <Error>{meta.error}</Error>}
      </Input>
    </Wrapper>
  );
};

export default Checkbox;
