import React from 'react';
import { useField } from 'formik';
import styled from 'styled-components';

import { REACT_APP_API_LOCATION } from '../../config';
import { Label, Wrapper } from './input';

const AddButton = styled.button`
  padding: 0.2em 0.5em;
`;
const ListFiles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const HiddenInput = styled.input`
  display: none;
`;

const Info = styled.div``;
const DeletedFile = styled.div`
  text-decoration: line-through;
`;

const DeleteButton = styled.button`
  padding: 0.2em 0.5em;
  margin-left: 0.5rem;
`;

const Files = ({ label, info, name }) => {
  const [field, , helper] = useField(name);
  const removeFile = React.useCallback(
    (id) => {
      const copyExistingFiles = [
        ...field.value?.filter((f) => f.url || f.id !== id),
      ];
      helper.setValue(
        copyExistingFiles?.map((file) =>
          file.id !== id ? file : { ...file, deleted: true },
        ),
      );
    },
    [field.value],
  );
  const fileInputRef = React.useRef(null);

  const addFile = React.useCallback(
    async (ev) => {
      const file = ev?.target?.files?.[0];
      if (!file) {
        return;
      }
      helper.setValue([
        ...field.value,
        {
          name: file.name,
          id: (Math.random() + 1).toString(36),
          newFileData: file,
        },
      ]);
    },
    [field.value],
  );

  const addNewFile = React.useCallback(() => {
    fileInputRef.current.click();
  }, []);

  return (
    <Wrapper>
      <Label htmlFor={name}>{label}</Label>
      <ListFiles>
        {info && <Info>{info}</Info>}
        {field.value?.map(({ id, url, name, deleted }) => {
          return (
            <div key={'savedfile' + id}>
              {url && !deleted ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={REACT_APP_API_LOCATION + url}
                  download>
                  {name}
                </a>
              ) : url && deleted ? (
                <DeletedFile>{name}</DeletedFile>
              ) : (
                name
              )}
              {!deleted && (
                <DeleteButton
                  type="button"
                  onClick={() => {
                    removeFile(id);
                  }}>
                  <span className="fas fa-trash-alt"></span>
                </DeleteButton>
              )}
            </div>
          );
        })}
        <HiddenInput
          ref={fileInputRef}
          type="file"
          onChange={addFile}></HiddenInput>
        <AddButton type="button" onClick={addNewFile}>
          Upload een bestand
        </AddButton>
      </ListFiles>
    </Wrapper>
  );
};

export default Files;
