import React from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';

import { UPDATE_DIER } from '../../graphql/index.js';
const DierEntry = styled.div`
  width: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px dotted lightgray;
  border-radius: 5px;
  padding: 0.125rem;
  justify-content: space-between;
`;
import ThreeWaySwitch from '../common/ThreeWaySwitch.js';

const DierDescription = styled.div``;

const Dier = ({ dier }) => {
  const type = dier?.type?.kort;

  const [isChecked, setChecked] = React.useState(dier.papersChecked);
  const [setPapersChecked] = useMutation(UPDATE_DIER, {
    // onCompleted: async (d) => {},
    onError: () => {
      // Toast
      // reset
    },
  });
  const handleSetPapers = React.useCallback((value) => {
    setChecked(value);
    setPapersChecked({
      variables: { id: dier.id, data: { papersChecked: value } },
    });
  }, []);
  return (
    <DierEntry>
      <DierDescription>
        {type === 'Andere'
          ? dier?.andere
          : dier?.leeftijd?.kort + type + ' ' + dier.naam}
      </DierDescription>
      {['1', '2', '3', '4'].includes(dier?.type?.id) && (
        <ThreeWaySwitch
          setValue={handleSetPapers}
          value={isChecked}
          type="checkPapers"
          tooltips={{
            true: 'BOEKJE OK',
            null: 'BOEKJE NOG NIET GECONTROLEERD',
            false: 'BOEKJE NIET OK',
          }}
        />
      )}
    </DierEntry>
  );
};

export default Dier;
