import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  gap: 1rem;
  align-items: center;
  justify-items: center;
  grid-template-areas:
    'naam naam'
    'switch waarborg'
    'dieren dieren'
    'opmerking opmerking';
  grid-template-columns: 1fr 1fr;
  border-bottom: 3px solid #666;
  min-height: 3rem;

  @media only screen and (min-width: 600px) and (max-width: 1000px) {
    grid-template-areas:
      'switch naam waarborg'
      'dieren dieren dieren'
      'opmerking opmerking opmerking';
    grid-template-columns: 130px auto 100px;
    border-bottom: 2px solid #666;
    min-height: 2rem;
  }

  @media only screen and (min-width: 1000px) {
    grid-template-areas: 'switch naam waarborg dieren opmerking';
    grid-template-columns: 130px 220px 100px 2fr 3fr;
    border-bottom: 2px solid #666;
    min-height: 2rem;
  }
`;

const Header = styled(Container)`
  background-color: #ffeedb;
  padding-left: 1rem;
  padding-right: 1rem;
`;

const Item = styled.div`
  text-align: center;
  width: 100%;
  @media only screen and (min-width: 600px) {
  }
`;

export const Switch = styled(Item)`
  grid-area: switch;
`;

export const Naam = styled(Item)`
  grid-area: naam;
  min-width: 220px;
`;

export const Waarborg = styled(Item)`
  grid-area: waarborg;
  min-width: 100px;
  & > input {
    width: 100%;
    padding: 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid #ccc;
    &::placeholder {
      color: rgba(0, 0, 0, 0.1);
    }
  }
`;

export const Dieren = styled(Item)`
  grid-area: dieren;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  align-items: stretch;
`;

export const Opmerking = styled(Item)`
  grid-area: opmerking;
`;

const BedelingTableHeader = () => {
  return (
    <Header>
      <Switch>geweest/komt niet</Switch>
      <Naam>Naam</Naam>
      <Waarborg>waarborg</Waarborg>
      <Dieren>dieren</Dieren>
      <Opmerking>Opmerking</Opmerking>
    </Header>
  );
};

export default BedelingTableHeader;
