import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import ActivityIndicator from './common/ActivityIndicator';

const SearchField = styled.input`
  border: 1px solid #ccc;
  width: 100%;
  font-size: 1rem;
  padding: 0.375rem 0.75rem;
  background-color: #fff;
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  line-height: 1.5rem;
`;

const ClearButton = styled.button`
  color: #6c757d;
  border: 1px solid #6c757d;
  border-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 2;
  padding: 0.375rem 0;
  margin-right: -1px;
  min-width: 34px;
  display: grid;
  place-content: center;
`;

const Xbutton = styled.div`
  padding: 0 0.75rem;
`;

const ButtonAndText = styled.div`
  display: flex;
  width: 100%;
`;

const SearchBox = ({
  searchString,
  setSearchString,
  placeholder,
  loading,
  disabled,
  className,
}) => {
  const [debounceTimer, setDebounceTimer] = React.useState();
  const [_loading, setLoading] = React.useState(false);
  const [_searchString, _setSearchString] = React.useState(searchString ?? '');
  React.useEffect(() => {
    _setSearchString(searchString);
  }, [searchString]);
  const { search } = useLocation();
  const clearSearch = React.useCallback(() => {
    const searchParams = new URLSearchParams(search);
    const currentSearch = searchParams.get('search');
    if (currentSearch) {
      searchParams.delete('search');
      window.location.search = searchParams.toString();
    }
    setSearchString('');
  }, [setSearchString]);

  const handleSearch = React.useCallback((value) => {
    setSearchString(value);
    setLoading(false);
  }, []);

  const handleOnValueChange = React.useCallback(
    (event) => {
      const handleSearchWrapper = () => {
        handleSearch(event.target.value);
      };
      _setSearchString(event.target.value);
      if (debounceTimer) clearTimeout(debounceTimer);
      setDebounceTimer(setTimeout(handleSearchWrapper, 500));
      setLoading(true);
    },
    [debounceTimer, handleSearch],
  );
  return (
    <ButtonAndText className={className}>
      <ClearButton
        onClick={clearSearch}
        type="button"
        disabled={_loading || disabled}>
        {_loading || loading ? (
          <ActivityIndicator size={10} />
        ) : (
          <Xbutton>X</Xbutton>
        )}
      </ClearButton>
      <SearchField
        type="text"
        placeholder={placeholder || 'begin te typen om te zoeken...'}
        aria-label=""
        onChange={handleOnValueChange}
        value={_searchString}
        aria-describedby="basic-addon1"
      />
    </ButtonAndText>
  );
};
export default SearchBox;
