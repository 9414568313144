import React, { memo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import styled, { css } from 'styled-components';

import {
  GET_BEDELING_LID_AANWEZIGHEDEN,
  SET_AANWEZIG,
} from '../../graphql/index.js';
import ThreeWaySwitch from '../common/ThreeWaySwitch.js';
import {
  Container,
  Dieren,
  Naam,
  Opmerking,
  Waarborg,
} from './BedelingTableHeader';
import Dier from './Dier.js';
import OpmerkingTT from './OpmerkingTT';
import OudeOpmerking from './OudeOpmerking.js';
const RowContainer = styled(Container)`
  border-bottom: 0.125rem solid #666666;
  border-collapse: collapse;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  padding-inline: 1rem;
  line-height: 2;
  ${({ actief, warning }) =>
    !actief &&
    css`
      text-decoration: line-through;
      background: ${warning
        ? `repeating-linear-gradient(-45deg, #fff, #fff 10px, #f97 10px, #f97 20px);`
        : `repeating-linear-gradient(
        -45deg,
        #fff,
        #fff 10px,
        #ddd 10px,
        #ddd 20px
      )`};
    `}}
    @media only screen and (max-width: 600px) {
  padding-inline: 0.25rem;
  border-bottom: 0.25rem solid #666666;
    }
`;

const Name = styled(Naam)`
  font-weight: bold;
  & > a {
    color: ${({ isRood }) => (isRood ? '#e85a1e' : '#337ab5')};
  }
  width: 220px;
  & > * {
    text-overflow: ellipsis;
  }
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const OudeOpmerkingen = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
`;

const OpmerkingField = styled.div`
  display: flex;
  border-radius: 0.25rem;
  width: 100%;
  min-width: 100px;
  border: 1px solid #ccc;
  overflow: hidden;

  & > input {
    width: 100%;
    font-weight: bolder;
    padding: 0.5rem;
    border: none;

    &::placeholder {
      color: rgba(0, 0, 0, 0.1);
      font-weight: normal;
    }
  }
  & > button {
    padding: 0.5em 1em;
    cursor: pointer;
  }
`;

const VisibleButton = styled.button`
  color: ${({ active, unusable }) =>
    unusable ? 'lightgrey' : active ? '#4caf50' : 'grey'};
  border: ${({ active, unusable }) =>
    active && !unusable ? '1px solid black' : 'none'};
  border-radius: 0.125rem;
  &:disabled {
    cursor: not-allowed;
    background-color: ${({ unusable }) => (unusable ? 'unset' : 'buttonface')};
  }
`;

const LidRow = memo(({ lid, handleWaarborg, bedelingId }) => {
  const [wbValue, setWbValue] = React.useState(lid.waarborgPotten);
  const { data: aanwezigData, refetch } = useQuery(
    GET_BEDELING_LID_AANWEZIGHEDEN,
    {
      variables: {
        lidId: lid?.id,
      },
      skip: !lid?.id,
      onCompleted: (data) => {
        const huidigeBedelingAanw = data?.aanwezighedens?.find(
          (aanw) => aanw.bedeling?.id == bedelingId,
        );
        setOpmerking(huidigeBedelingAanw?.opmerking ?? null);
        setIsAanwezig(huidigeBedelingAanw?.aanwezig ?? null);
      },
    },
  );

  const lidAanwezigheid = aanwezigData?.aanwezighedens?.find(
    (aanw) => aanw.bedeling?.id == bedelingId,
  );
  const oudeOpmerkingen = aanwezigData?.aanwezighedens?.reduce(
    (opm, aanw) =>
      aanw.bedeling?.id == bedelingId ||
      aanw.isOpmerkingVerborgen ||
      !aanw.opmerking
        ? opm
        : [...opm, aanw],
    [],
  );
  const [opmerking, setOpmerking] = React.useState(lidAanwezigheid?.opmerking);
  const [isAanwezig, setIsAanwezig] = React.useState(lidAanwezigheid?.aanwezig);

  const isRood = React.useMemo(() => lid.textfinder?.indexOf('???') >= 0, [
    lid.textfinder,
  ]);

  const [setAanwezig] = useMutation(SET_AANWEZIG, {
    onCompleted: async () => {
      await refetch();
    },
    onError: () => {
      // Toast
      // reset
    },
  });

  const handleAanwezig = useCallback(
    (value) => {
      setIsAanwezig(value);
      setAanwezig({
        variables: { lidId: lid.id, bedelingId, aanwezig: value },
      });
    },
    [setAanwezig, lid.id, bedelingId],
  );

  const handleOpm = React.useCallback(
    (lidId, value) => {
      setAanwezig({
        variables: {
          lidId,
          bedelingId,
          opmerking: value || null,
        },
      });
    },
    [bedelingId, setAanwezig],
  );
  // const handleToggleOpmVisibility = React.useCallback(() => {
  //   if (lidAanwezigheid && opmerking) {
  //     setAanwezig({
  //       variables: {
  //         lidId: lid.id,
  //         bedelingId,
  //         isOpmerkingVerborgen: !lidAanwezigheid.isOpmerkingVerborgen,
  //       },
  //     });
  //   }
  // }, [lid, lidAanwezigheid, bedelingId]);

  const handleShowOpmerking = React.useCallback(() => {
    setAanwezig({
      variables: {
        lidId: lid.id,
        bedelingId,
        isOpmerkingVerborgen: false,
      },
    });
  }, [lid, bedelingId]);
  const handleHideOpmerking = React.useCallback(() => {
    setAanwezig({
      variables: {
        lidId: lid.id,
        bedelingId,
        isOpmerkingVerborgen: true,
      },
    });
  }, [lid, bedelingId]);

  if (!lid?.id) {
    return <></>;
  }
  return (
    <RowContainer
      warning={lid.opmerking}
      actief={lid.actief}
      key={'l' + lid.id}>
      <ThreeWaySwitch
        setValue={handleAanwezig}
        value={isAanwezig}
        type="been"
        tooltips={{
          true: 'GEWEEST',
          null: 'NIET GEWEEST',
          false: 'VERWITTIGD: KAN NIET KOMEN',
        }}
      />
      <Name isRood={isRood}>
        <Link to={'/leden/edit/' + lid.id}>
          {lid.lname} {lid.fname}
        </Link>
        {(lid.opmerking || lid.dierenopmerkingen?.length > 0) && (
          <OpmerkingTT
            lidtext={lid.opmerking}
            diertext={lid.dierenopmerkingen}
          />
        )}
      </Name>
      <Waarborg className="waarborg">
        <input
          type="text"
          className="waarborgbedeling"
          data-lid_id={lid.id}
          value={wbValue || ''}
          onChange={(ev) => setWbValue(ev.target.value)}
          onBlur={() => handleWaarborg(lid.id, wbValue)}
          placeholder="Waarborg"
        />
      </Waarborg>
      <Dieren>
        {lid.huisdieren?.map((dier) => (
          <Dier dier={dier} key={'d' + dier.id} />
        ))}
      </Dieren>
      <Opmerking>
        {oudeOpmerkingen?.length > 0 && (
          <OudeOpmerkingen>
            {oudeOpmerkingen.map(
              ({ id, bedeling: { datum, id: bedelingId }, opmerking }) => (
                <OudeOpmerking
                  key={id}
                  id={id}
                  datum={datum}
                  opmerking={opmerking}
                  bedelingId={bedelingId}
                  lidName={lid.fname + ' ' + lid.lname}
                  lidId={lid.id}
                  setAanwezig={setAanwezig}
                />
              ),
            )}
          </OudeOpmerkingen>
        )}

        <OpmerkingField>
          <input
            type="text"
            placeholder="Opmerking voor dit lid voor deze bedeling"
            aria-label=""
            onChange={(ev) => setOpmerking(ev.target.value)}
            value={
              opmerking || ''
              // (lidAanwezigheid?.isOpmerkingVerborgen ? 'verborgen' : '') || ''
            }
            onBlur={(ev) => handleOpm(lid.id, ev.target.value)}></input>
          <VisibleButton
            onClick={handleShowOpmerking}
            type="button"
            active={!lidAanwezigheid?.isOpmerkingVerborgen}
            unusable={!opmerking}
            disabled={!opmerking || !lidAanwezigheid?.isOpmerkingVerborgen}>
            <i
              className="fas fa-eye"
              title={
                opmerking
                  ? !lidAanwezigheid?.isOpmerkingVerborgen
                    ? 'Opmerking is zichtbaar in andere bedelingen'
                    : 'Toon opmerking in andere bedelingen'
                  : ''
              }></i>
          </VisibleButton>
          <VisibleButton
            onClick={handleHideOpmerking}
            type="button"
            active={lidAanwezigheid?.isOpmerkingVerborgen}
            unusable={!opmerking}
            disabled={!opmerking || lidAanwezigheid?.isOpmerkingVerborgen}>
            <i
              className="fas fa-eye-slash"
              title={
                opmerking
                  ? lidAanwezigheid?.isOpmerkingVerborgen
                    ? 'Opmerking is verborgen in andere bedelingen'
                    : 'Verberg opmerking in andere bedelingen'
                  : ''
              }></i>
          </VisibleButton>
        </OpmerkingField>
      </Opmerking>
    </RowContainer>
  );
});

export default LidRow;
