import * as React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

import { boxShadowCss } from './box';

const Container = styled.div`
  max-height: calc(95dvh - 4rem);
  display: flex;
  flex-direction: column;
`;

const StyledModal = styled(Modal)`
  position: absolute;
  z-index: 9999;
  padding: 0.25rem 0;
  top: 52%; // a bit down to take into account the header
  left: 50%;
  width: 40rem;
  max-width: 60rem;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid black;
  border-radius: 1rem;
  ${boxShadowCss}

  transform-origin: center center;
  /* Fade in animation */
  animation: fade-in 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) both;
  @keyframes fade-in {
    0% {
      transform: translate(-50%, -50%) scale(0);
      opacity: 0;
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  }
  width: 95%;

  @media (min-width: 600px) {
    padding: 2rem 0;
    width: 90%;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: transparent;
  border: none;
  font-size: 2rem;
  font-weight: 700;
  cursor: pointer;
  color: black;
`;

const ChildrenWrapper = styled.div`
  color: black;
  white-space: pre-line;
  font-size: 1.1rem;
  overflow-y: auto;
  padding: 0 1.5rem;
`;

const Title = styled.h2`
  padding: 0 1.5rem;
`;

const PopupModal = ({
  setPopup,
  children,
  visible,
  className,
  canClose,
  onOutsideClick = () => {
    if (!canClose) return;
    setPopup(false);
  },
  title,
}) => {
  // for some reason this needed. it should only add a class to body but on messages page it adds a style attribute and doesn't remove it
  React.useEffect(() => {
    visible
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');
  }, [visible]);

  return (
    <StyledModal
      isOpen={visible}
      className={className}
      shouldCloseOnOverlayClick
      onRequestClose={onOutsideClick}>
      <Container>
        {title && <Title>{title}</Title>}
        {canClose && <CloseButton onClick={onOutsideClick}>X</CloseButton>}
        <ChildrenWrapper>{children}</ChildrenWrapper>
      </Container>
    </StyledModal>
  );
};
PopupModal.displayName = 'PopupModal';

export default React.memo(PopupModal);
