import React from 'react';
import styled from 'styled-components';

const Modal = styled.div`
  position: fixed;
  left: 50%;
  background-color: #cccccc;
  width: 150px;
  /*half the size of width*/
  margin-left: -75px;
  z-index: 999;
  text-align: center;
  border: 1px solid black;
  top: 50%;
  height: 130px;
  /*half the size of height*/
  margin-top: -65px;
  padding-top: 15px;

  box-shadow: rgb(0 0 0 / 50%) 0.1rem 0.2rem 1rem;

  & > i {
    font-size: 4rem;
  }
`;

const SavedModalModal = () => {
  return (
    <Modal>
      <i className="fas fa-check"></i>
      <p>Save OK</p>
    </Modal>
  );
};
export default SavedModalModal;

export const showSavedModalBriefly = (setShowSavedModal, next) => {
  setShowSavedModal(true);
  setTimeout(() => {
    setShowSavedModal(false);
    next?.();
  }, 2000);
};
