import React from 'react';
import { useField } from 'formik';
import styled from 'styled-components';

import { Error, Input, Label, Wrapper } from '.';

export const RadioButtonLabel = styled.label`
  display: flex;
  gap: 0.5rem;
  line-height: 1rem;
`;

const RadioButtons = ({ values, name, label, required, id, disabled }) => {
  const [field, meta] = useField(name);
  return (
    <Wrapper>
      <Label htmlFor={name}>{label}</Label>
      <Input>
        {values?.map((value) => (
          <RadioButtonLabel htmlFor={`${id}-${value.value}`} key={value.id}>
            <input
              type="radio"
              required={required}
              defaultChecked={field.value === value.id}
              {...field}
              value={value.id}
              id={`${id}-${value.value}`}
              disabled={disabled}
            />
            {value.value}
          </RadioButtonLabel>
        ))}
        {meta.error && meta.touched && <Error>{meta.error}</Error>}
      </Input>
    </Wrapper>
  );
};

export default RadioButtons;
