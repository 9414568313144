import React from 'react';
import { useField } from 'formik';

import { Error, Input, Label, Wrapper } from '.';
import { RadioButtonLabel } from './radiobuttons';
import Select from './select';

const RadioButtonsWithOther = ({
  values,
  other,
  otherValues,
  name,
  label,
  required,
  id,
}) => {
  const [field, meta, helpers] = useField(name);
  const selectOther = React.useCallback(() => {
    helpers.setValue(other.id);
  }, []);
  return (
    <Wrapper>
      <Label htmlFor={name}>{label}</Label>
      <Input>
        {values?.map((value) => (
          <RadioButtonLabel key={value.id} htmlFor={`${id}-${value.value}`}>
            <input
              type="radio"
              required={required}
              {...field}
              value={value.id}
              checked={field.value == value.id}
              id={`${id}-${value.value}`}
            />
            {value.value}
          </RadioButtonLabel>
        ))}
        <RadioButtonLabel htmlFor={`${id}-other`}>
          <input
            type="radio"
            required={required}
            {...field}
            value={other.id}
            checked={field.value == other.id}
            id={`${id}-other`}
          />
          <Select
            name={name.includes('.') ? name.split('.')[0] + 'andere' : 'andere'}
            noLabel
            values={otherValues}
            defaultDisabled
            onChange={selectOther}
          />
        </RadioButtonLabel>
        {meta.error && meta.touched && <Error>{meta.error}</Error>}
      </Input>
    </Wrapper>
  );
};

export default RadioButtonsWithOther;
