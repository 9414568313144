import React from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import { extractApolloErrorMessage } from '../auth/index.js';
import { GET_LOCATIES } from '../graphql/index.js';
import Button from './common/Button.js';
import LoadingDiv from './common/LoadingDiv';

const Container = styled.div`
  border-color: black;
  border-style: dashed;
  border-width: 1px;
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 600px) {
    flex-direction: row;
  }
`;

const LocatieFilter = ({ userLocations, locationToFilter, changeLocation }) => {
  const { loading, data, error } = useQuery(GET_LOCATIES);
  if (error) return <div>Error: {extractApolloErrorMessage(error)}</div>;
  const locaties = data?.locaties;

  const onlyOneLocation = userLocations
    ? userLocations.length === 1
    : undefined;

  if (onlyOneLocation === true) {
    return null;
  }
  return (
    <Container>
      <div>Filter:</div>
      {locaties && userLocations?.length > 1 && (
        <Button
          primary={locationToFilter === 0}
          key="loc0"
          type="button"
          data-filterbutton="0"
          value="Alles"
          onClick={() => changeLocation(0)}>
          Alles
        </Button>
      )}
      {locaties &&
        userLocations?.map((loc) => {
          return (
            <Button
              primary={locationToFilter == loc}
              type="button"
              key={'loc' + loc}
              data-filterbutton={loc}
              onClick={() => userLocations.length > 1 && changeLocation(loc)}>
              {locaties.find((x) => x.id == loc)?.Naam || ''}
            </Button>
          );
        })}
      {loading && <LoadingDiv />}
    </Container>
  );
};

export default LocatieFilter;
