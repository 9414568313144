import React from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import { GET_DIEREN, GET_TLL } from '../graphql/index.js';
import LoadingDivModal from './common/LoadingDivModal';
import Page from './common/page.js';
import TopTitle from './common/TopTitle.js';
import LocatieFilter from './LocatieFilter';

const TitleAndFilter = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 600px) {
    flex-direction: row;

    justify-content: space-between;
  }
`;

const Dieren = ({
  locations: { userLocations, locationToFilter, changeLocation },
}) => {
  const [dieren, setDieren] = React.useState([]);

  const { loading: loadingDieren, data: dataDieren } = useQuery(GET_DIEREN, {
    // pollInterval: 5000,
  });

  const { loading: loadingTLL, data: dataTLL } = useQuery(GET_TLL, {
    // pollInterval: 5000,
  });

  const types = React.useMemo(
    () =>
      dataTLL
        ? dataTLL.tll.types.filter((x) => x.kort.toLowerCase() !== 'andere')
        : [],
    [dataTLL],
  );
  const leeftijden = React.useMemo(
    () => (dataTLL ? dataTLL.tll.leeftijds : []),
    [dataTLL],
  );

  const dierenBackup = React.useMemo(() => dataDieren?.huisdierens, [
    dataDieren?.huisdierens,
  ]);

  React.useEffect(() => {
    setDieren(
      locationToFilter && userLocations?.length !== 1
        ? dierenBackup?.filter(
            (x) => x.baasje?.locatie.id == locationToFilter && x.baasje.actief,
          )
        : dierenBackup?.filter((x) => x?.baasje.actief),
    );
  }, [dierenBackup, locationToFilter, userLocations]);

  const vasteDieren = React.useMemo(
    () => dieren?.filter((x) => x?.type?.kort.toLowerCase() !== 'andere') ?? [],
    [dieren],
  );
  const andereDieren = React.useMemo(
    () => dieren?.filter((x) => x?.type?.kort.toLowerCase() === 'andere') ?? [],
    [dieren],
  );

  const uniekeAndereDieren = [...new Set(andereDieren?.map((x) => x?.andere))];
  return (
    <Page>
      {loadingTLL && loadingDieren && <LoadingDivModal />}
      <TitleAndFilter>
        <TopTitle>DIEREN</TopTitle>
        <LocatieFilter
          userLocations={userLocations}
          locationToFilter={locationToFilter}
          changeLocation={changeLocation}
        />
      </TitleAndFilter>

      <div className="titelDieren">VASTE TYPES: {vasteDieren?.length}</div>
      <div className="container-fluid" id="table_container">
        <div className="table-row header">
          <div className="column leftcolumn"></div>
          {types?.map((type) => {
            return (
              <div className="column animaltype" key={'t' + type.id}>
                {type.kort}
              </div>
            );
          })}
        </div>
        {leeftijden?.map((leeftijd) => {
          return (
            <div className="table-row" key={'l' + leeftijd.id}>
              <div className="column leftcolumn">{leeftijd.omschrijving}</div>
              {types?.map((type) => {
                const length = vasteDieren.filter((x) => {
                  return (
                    x?.type?.id === type?.id && x?.leeftijd?.id === leeftijd?.id
                  );
                }).length;
                return (
                  <div
                    className="column animaltype"
                    key={'t' + type.id + 'l' + leeftijd.id}>
                    {length ? (
                      <a
                        href={`leden?search=${leeftijd.kort}${type.kort}; actief:ja`}
                        style={{ padding: '1rem' }}>
                        {length}
                      </a>
                    ) : (
                      length
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="titelDieren">ANDERE TYPES: {andereDieren?.length}</div>
      <div className="container-fluid" id="anderetypes">
        {uniekeAndereDieren?.map((dier) => {
          return (
            <div className="table-row" key={dier}>
              <div className="column leftcolumn">{dier}</div>
              <div className="column otheranimaltype">
                {
                  andereDieren?.filter((x) => {
                    return x?.andere === dier;
                  })?.length
                }
              </div>
            </div>
          );
        })}
      </div>
    </Page>
  );
};

export default Dieren;
