import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { FieldArray, Form, Formik, useFormikContext } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';

import { extractApolloErrorMessage } from '../auth';
import {
  CREATE_DIER,
  CREATE_LID,
  GET_TLL,
  UPLOAD_FILE_SINGLE,
} from '../graphql/index';
import Box, { boxShadowCss } from './common/box';
import Button from './common/Button';
import Files from './common/Files';
import RadioButtons from './common/input/radiobuttons';
import RadioButtonsWithOther from './common/input/radiobuttonsWithOther';
import TextInput from './common/input/textInput';
import LoadingDivModal from './common/LoadingDivModal';
import Page from './common/page';
import Row from './common/Row';
import SavedModal, { showSavedModalBriefly } from './common/SavedModal';

const StyledRow = styled(Row)`
  margin: 2rem;
  margin-bottom: 8rem;
`;
const Info = styled.div`
  font-size: 1rem;
  margin-block: 2rem;
`;

const StyledLink = styled(Link)`
  color: #e85a1e;
  font-weight: 600;
  text-decoration: underline;
`;

const Error = styled.div`
  margin: 5rem;
  color: red;
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
`;

// we aanvaarden alle postcodes van gent, deelgemeenten van gent, en buurgemeenten van gent
// zie https://www.hugswithtails.be/dierenvoedselbank/#huisregels
const aanvaardePostcodes = [
  // gent
  '9000',
  '9000',
  '9030',
  '9031',
  '9032',
  '9040',
  '9041',
  '9042',
  '9050',
  '9051',
  '9052',
  // zelzate
  '9060',
  // destelbergen
  '9070',
  // lochristi
  '9080',
  // melle
  '9090',
  // wachtebeke
  '9185',
  // deinze
  '9800',
  // merelbeke
  '9820',
  // sint-martems-latem
  '9830',
  '9831',
  // de pinte
  '9840',
  // nevele
  '9850',
  // lievegem
  '9920',
  '9921',
  '9930',
  '9931',
  '9932',
  '9950',
  // evergem
  '9940',
];

const LidSchema = Yup.object().shape({
  fname: Yup.string().required('Voornaam is verplicht'),
  lname: Yup.string().required('Familienaam is verplicht'),
  email: Yup.string()
    .email('Geen geldig e-mail adres')
    .required('E-mail adres is verplicht'),
  address: Yup.string().required('Straat en huisnummer is verplicht'),
  postcode: Yup.string()
    .length(4, 'Postcode moet 4 cijfers bevatten')
    .oneOf(aanvaardePostcodes, 'Deze postcode bedienen we niet')
    .required('Postcode is verplicht'),
  phoneNumber: Yup.string().optional(),
  city: Yup.string().required('Gemeente is verplicht'),
  situatie: Yup.string().required('Situatie is verplicht'),
  huisdieren: Yup.array().of(
    Yup.object().shape({
      naam: Yup.string().required('Naam huisdier is verplicht'),
      type: Yup.string().required('Type huisdier is verplicht'),
      leeftijd: Yup.string().when('type', {
        is: (type) => type !== '5',
        then: Yup.string().required('Leeftijd huisdier is verplicht'),
        otherwise: Yup.string().optional(),
      }),
      andere: Yup.string().when('type', {
        is: '5',
        then: Yup.string().required('Een ander type kiezen is verplicht'),
        otherwise: Yup.string().optional(),
      }),
      opmerking: Yup.string().optional(),
    }),
  ),
});

const Banner = styled.img`
  --margin-inline: 0.2rem;
  margin-top: 1rem;
  max-width: calc(100% - 2 * var(--margin-inline));
  border-radius: 0.625rem;
  margin-inline: var(--margin-inline);
  ${boxShadowCss}

  @media only screen and (min-width: 600px) {
    --margin-inline: 1rem;
  }
`;

const StyledButton = styled(Button)`
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 0.625rem;
  ${boxShadowCss}
  color: #333740;
`;

const StyledButton2 = styled(Button)`
  background-color: '#4caf50';
  border: 1px solid #4caf50;
  border-radius: 0.625rem;
  ${boxShadowCss}
  color: white;
`;

const StyledButton3 = styled(Button)`
  background-color: '#FF0000';
  border: 1px solid #ff0000;
  border-radius: 0.625rem;
  color: white;
  display: flex;
  align-items: center;
  gap: 1rem;
  & i {
    font-size: 1.5rem;
  }
`;

const DierBox = ({ dierindex, tll, arrayHelpers }) => {
  const handleDierDelete = React.useCallback(() => {
    arrayHelpers.remove(dierindex);
  }, [arrayHelpers, dierindex]);
  const { types, leeftijds, andereTypes } = tll;
  const typeValues = types
    ?.filter((type) => type.omschrijving !== 'Andere')
    .map((type) => ({ id: type.id, value: type.omschrijving }));
  const andereTypeValues = andereTypes?.map((type) => ({
    id: type.omschrijving,
    value: type.omschrijving,
  }));
  const leeftijdValues = leeftijds?.map((leeftijd) => ({
    id: leeftijd.id,
    value: leeftijd.omschrijving,
  }));

  const { values } = useFormikContext();
  const isHondOfKat = ['1', '2', '3', '4'].includes(
    values.huisdieren[dierindex]?.type,
  );

  return (
    <Box title={`Gegevens van uw ${dierindex + 1}ᵉ huisdier `}>
      <TextInput name={`huisdieren[${dierindex}].naam`} label="Naam" required />
      <RadioButtonsWithOther
        id={`dier${dierindex}`}
        name={`huisdieren[${dierindex}].type`}
        label="Type"
        required
        values={typeValues}
        otherValues={andereTypeValues}
        other={{ id: '5', value: null }}
      />
      {isHondOfKat && (
        <RadioButtons
          id={`dier${dierindex}`}
          name={`huisdieren[${dierindex}].leeftijd`}
          label="Leeftijd"
          required
          values={leeftijdValues}
        />
      )}
      <TextInput
        name={`huisdieren[${dierindex}].opmerking`}
        label="Opmerking (niet verplicht)"
        placeholder="Als u opmerkingen heeft, bijvoorbeeld 'slechte tanden' of 'dieetvoeding' kan u dit hier invullen (niet verplicht)"
        multiline
      />
      {dierindex > 0 && (
        <StyledButton3 secondary onClick={handleDierDelete}>
          <i className="far fa-minus-square"></i> Dier Verwijderen
        </StyledButton3>
      )}
    </Box>
  );
};

const FormContent = ({
  dataTLL,
  showDuplicateEmailError,
  clearDuplicateEmailError,
}) => {
  const { submitForm, values, errors, isValid, touched } = useFormikContext();
  const isEveryErrorFieldTouched = React.useMemo(
    () => Object.keys(errors).every((key) => touched.hasOwnProperty(key)),
    [errors, touched],
  );
  const arrayHelpersRef = React.useRef(null);
  const renderDieren = useCallback(
    (arrayHelpers) => {
      arrayHelpersRef.current = arrayHelpers;
      return (
        <>
          {values?.huisdieren?.map((_, dierindex) => (
            <DierBox
              key={dierindex}
              dierindex={dierindex}
              tll={dataTLL}
              arrayHelpers={arrayHelpers}
            />
          ))}
        </>
      );
    },
    [arrayHelpersRef.current, values?.huisdieren],
  );
  const handleAddHuisdier = useCallback(() => {
    arrayHelpersRef.current.push({
      naam: '',
      type: '',
      leeftijd: '',
      andere: undefined,
      opmerking: '',
    });
  }, [arrayHelpersRef.current]);
  return (
    <Form>
      <Box title="Uw gegevens">
        <TextInput name="fname" label="Voornaam" required />
        <TextInput name="lname" label="Familienaam" required />
        <TextInput
          name="email"
          label="E-mail adres"
          onChange={clearDuplicateEmailError}
          required
          // info="Wij sturen u GEEN ongevraagde e-mails"
          error={showDuplicateEmailError ? 'Dit e-mail adres bestaat al' : null}
        />
        <TextInput
          name="phoneNumber"
          label="Telefoonnummer (niet verplicht)"
          placeholder="Telefoonnummer"
        />
        <TextInput
          name="address"
          label="Straat en huisnummer"
          placeholder="Straat en huisnummer (en eventueel busnummer)"
          // info="Wij sturen u GEEN ongevraagde post"
          required
        />
        <TextInput name="postcode" label="Postcode" required />
        <TextInput name="city" label="Gemeente" required />
        <TextInput
          name="situatie"
          label="Korte omschrijving van uw situatie"
          info="Let op: we vragen GEEN inkomsten"
          multiline
          required
        />
        <Files
          name="bestanden"
          label="Bestanden (niet verplicht)"
          info={
            <div>
              U kunt hier alvast uw bestanden uploaden. (bvb bewijsstukken van
              OCMW/CAW/KRAS/invaliditeit/schuldbemiddelaar/...) <br />
              Of u kunt ze ook later doormailen of meebrengen naar de bedeling.
            </div>
          }
        />
      </Box>

      {dataTLL && (
        <>
          <FieldArray name="huisdieren" render={renderDieren} />
          <StyledRow>
            <StyledButton2
              primary
              onClick={submitForm}
              disabled={
                (!isValid && isEveryErrorFieldTouched) ||
                showDuplicateEmailError
              }
              disabledReason="Er zijn fouten in het formulier, kijk alle velden na">
              <i className="far fa-check-square"></i>Verzenden
            </StyledButton2>
            {values.huisdieren.length < 4 && (
              <StyledButton onClick={handleAddHuisdier}>
                <i className="far fa-plus-square"></i> Dier toevoegen
              </StyledButton>
            )}
          </StyledRow>
        </>
      )}
    </Form>
  );
};
const initialValues = {
  fname: '',
  lname: '',
  address: '',
  postcode: '',
  city: '',
  phoneNumber: '',
  email: '',
  situatie: '',
  locatie: 1,
  bestanden: [],
  huisdieren: [
    {
      naam: '',
      type: '',
      leeftijd: '',
      andere: undefined,
      opmerking: '',
    },
  ],
};

const Lid = () => {
  const [showSavedModal, setShowSavedModal] = useState(false);
  const [showDuplicateEmailError, setDuplicateEmailError] = useState('');
  const [error, setError] = useState(null);

  const clearDuplicateEmailError = React.useCallback(() => {
    setDuplicateEmailError(false);
  }, []);

  const { loading: loadingTLL, data: dataTLL } = useQuery(GET_TLL, {
    onCompleted: (data) => {
      console.log('dataTLL loaded :>> ', data);
    },
    onError: (err) => {
      console.log('err :>> ', err);
      console.log('extracted message :>> ', extractApolloErrorMessage(err));
      setError(extractApolloErrorMessage(err));
    },
  });
  console.log('loadingTLL :>> ', loadingTLL);
  console.log('dataTLL :>> ', dataTLL);

  const [saveLid, { loading: loadingSaveLid }] = useMutation(CREATE_LID, {
    onCompleted: () => {
      showSavedModalBriefly(setShowSavedModal, () => {
        window.parent.location.href =
          'https://www.hugswithtails.be/inschrijving-ok/';
      });
    },
    onError: (err) => {
      const errorCode = extractApolloErrorMessage(err);
      switch (errorCode) {
        case 'Duplicate entry':
          console.log('DUPLICATE ENTRY');
          setDuplicateEmailError(true);
      }
    },
  });

  const [saveDier, { loading: loadingSaveDier }] = useMutation(CREATE_DIER);

  const [uploadFile, { loading: loadingUploadFile }] = useMutation(
    UPLOAD_FILE_SINGLE,
  );

  const handleSubmit = useCallback(
    async (values) => {
      const { huisdieren, bestanden: _, ...rest } = values;
      const huidierenIds = await Promise.all(
        huisdieren.map(async (dier) => {
          return saveDier({
            variables: {
              data: dier,
            },
          });
        }),
      );

      const localFiles = values.bestanden.filter((f) => !f.url);
      const newFileIds = await Promise.all(
        localFiles.map(
          async ({ newFileData }) =>
            (
              await uploadFile({
                variables: { file: newFileData },
              })
            ).data.upload.id,
        ),
      );

      await saveLid({
        variables: {
          data: {
            ...rest,
            postcode: parseInt(rest.postcode, 10),
            sinds: new Date(),
            huisdieren: huidierenIds.map(
              (data) => data.data.createHuisdieren.huisdieren.id,
            ),
            bestanden: newFileIds,
          },
        },
      });
      console.log('lid saved');
    },
    [saveLid],
  );

  return (
    <Page>
      {(loadingSaveLid ||
        loadingTLL ||
        loadingUploadFile ||
        loadingSaveDier) && <LoadingDivModal />}
      <Banner
        className="rounded mx-auto d-block img-fluid"
        alt="dierenvoedelbank banner"
        src="/img/dvb_header2.jpg"
      />
      <Box
        top
        title="Inschrijving dierenvoedselbank Gent"
        subtitle="Formulier voor lidmaatschap">
        <Info>
          Lidmaatschap is voor baasjes uit Gent (incl. deelgemeenten) en
          aangrenzende gemeentes, die tijdelijk of langdurig in financiële
          moeilijkheden verkeren (geholpen worden door het OCMW, CAW, KRAS of
          een schuldbemiddelaar, laag pensioen, invaliditeit, werkloosheid, hoge
          medische kosten, etc...).
        </Info>
        <Info>
          Inschrijven is gratis maar wel verplicht. (zodat we tijdig kunnen
          weten hoeveel mensen we kunnen verwachten)
        </Info>
        <Info>
          U mag maximum 4 huisdieren inschrijven. Als u meer dan 4 huisdieren
          wil inschrijven, of als u een type wil inschrijven dat niet
          beschikbaar is in de lijst, gelieve dan{' '}
          <StyledLink
            target="_blank"
            to={{ pathname: 'https://www.hugswithtails.be/contact/' }}>
            contact op te nemen
          </StyledLink>
        </Info>
      </Box>
      {error && (
        <Error>
          ERROR. Gelieve dit ons onderstaande foutmelding te laten weten via{' '}
          <StyledLink
            target="_blank"
            to={{ pathname: 'https://www.hugswithtails.be/contact/' }}>
            een van deze manieren
          </StyledLink>
          , dan kunnen we dit proberen oplossen. Dank u wel.
          <br />
          <br />
          <quote>Foutmelding: {error}</quote>
        </Error>
      )}
      {!loadingTLL && dataTLL?.tll && (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validateOnMount
          validationSchema={LidSchema}>
          <FormContent
            dataTLL={dataTLL.tll}
            showDuplicateEmailError={showDuplicateEmailError}
            clearDuplicateEmailError={clearDuplicateEmailError}
          />
        </Formik>
      )}

      {showSavedModal && <SavedModal />}
    </Page>
  );
};

export default Lid;
