import React from 'react';
import styled, { css } from 'styled-components';
export const boxShadowCss = css`
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
`;

const StyledBox = styled.div`
  position:relative;
  border: 1px solid #ccc;
  border-radius: 0.625rem;
  margin-top: 2rem;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  padding: 1rem;
  ${boxShadowCss}
  border-top: ${({ top }) =>
    top ? '0.625rem solid #e85a1e' : '2px solid #e85a1e'};
  @media only screen and (min-width: 600px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
`;

const Title = styled.div`
  margin-block: 2rem;
  ${({ hasSubtitle }) =>
    hasSubtitle
      ? css`
          font-size: 35px;
          font-weight: 400;
        `
      : css`
          font-size: 20px;
          font-weight: 700;
        `}
  text-align: center;
`;

const Subtitle = styled.div`
  margin-bottom: 3rem;
  text-align: center;
  font-size: 20px;
`;

const Box = ({ children, top, title, subtitle }) => {
  return (
    <StyledBox top={top}>
      <Title hasSubtitle={Boolean(subtitle)}>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {children}
    </StyledBox>
  );
};

export default Box;
