import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Form, Formik, useFormikContext } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';

import { useAuth } from '../auth';
import { UPDATE_ME } from '../graphql';
import Box from './common/box';
import Button from './common/Button';
import TextInput from './common/input/textInput';
import LoadingDivModal from './common/LoadingDivModal';
import Page from './common/page';
import SavedModal, { showSavedModalBriefly } from './common/SavedModal';

const SettingsSchema = Yup.object().shape({
  email: Yup.string().required(),
});

const FormContentSettings = () => {
  const { submitForm } = useFormikContext();
  return (
    <Form>
      <TextInput name="email" label="E-mail adres" required disabled />
      <Button primary onClick={submitForm} disabled>
        Instellingen Opslaan
      </Button>
    </Form>
  );
};

const LogOutButton = styled.button`
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  position: absolute;
  right: 0;
  top: 0;
  margin: 1rem;
`;

const Instellingen = () => {
  const [showSavedModal, setShowSavedModal] = useState(false);
  const { logout, currentUser, loadingAuth } = useAuth();
  const [saveLid, { loading: loadingSaveLid }] = useMutation(UPDATE_ME, {
    onCompleted: () => {
      showSavedModalBriefly(setShowSavedModal);
    },
  });

  const initialValues = {
    email: currentUser?.email ?? '',
  };

  const handleSubmit = React.useCallback((values) => {
    saveLid({ variables: values });
  }, []);

  const performLogout = React.useCallback(() => {
    logout();
    window.location.href = '/';
  }, []);

  return (
    <Page>
      <Box top title="Instellingen">
        {(loadingAuth || loadingSaveLid) && <LoadingDivModal />}
        {showSavedModal && <SavedModal />}
        Je bent aangemeld als gebruiker: {currentUser?.username}.
        <LogOutButton
          className="btn btn-primary"
          onClick={performLogout}
          type="button">
          LOG OUT
        </LogOutButton>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={SettingsSchema}>
          <FormContentSettings />
        </Formik>
      </Box>
    </Page>
  );
};

export default Instellingen;
