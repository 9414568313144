import './css/fontawesome.css';

import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { ApolloProvider } from '@apollo/client';
import { createGlobalStyle } from 'styled-components';

import { AuthorizedRoute } from './auth';
import Bedelingen from './components/Bedelingen';
import BedelingView from './components/BedelingView';
import ScrollButton from './components/common/ScrollButton';
import Dieren from './components/Dieren';
import { useLocations } from './components/Helpers';
import Leden from './components/Leden';
import LedenEdit from './components/LedenEdit';
import LedenNew from './components/LedenNew';
import Login from './components/Login';
import Messages from './components/messages';
import Nav from './components/Nav';
import NotFound from './components/NotFound';
import Settings from './components/Settings';
import { client } from './graphql/apolloclient';
import { GET_ME } from './graphql/index';

const NavRoute = ({ showNav = false, component: Component, ...routeProps }) => {
  const locations = useLocations();
  useQuery(GET_ME, {
    onCompleted: (data) => {
      locations.setUserLocations(
        data?.me?.user?.locaties?.map((loc) => loc.id) ?? [],
      );
    },
  });
  return (
    <AuthorizedRoute
      {...routeProps}
      render={(componentProps) => (
        <div>
          {showNav && <Nav {...componentProps} />}
          <Component {...componentProps} locations={locations} />
          <ScrollButton />
        </div>
      )}
    />
  );
};

const App = () => {
  return (
    <div className="App">
      <Router>
        <Switch>
          <NavRoute
            exact
            component={Login}
            path="/"
            redirectIfNotAuthorized="/leden"
          />
          <NavRoute exact component={Login} path="/login" skipRedirect />
          <Route exact component={LedenNew} path="/leden/new" />
          <NavRoute showNav exact component={Messages} path="/messages" />
          <NavRoute showNav component={LedenEdit} path="/leden/edit/:id" />
          <NavRoute showNav component={Leden} path="/leden" />
          <NavRoute
            showNav
            component={BedelingView}
            path="/bedelingen/view/:id"
          />
          <NavRoute showNav component={Bedelingen} path="/bedelingen" />
          <NavRoute showNav component={Settings} path="/instellingen" />
          <NavRoute showNav component={Dieren} path="/dieren" />
          <NavRoute showNav component={NotFound} />
        </Switch>
      </Router>
    </div>
  );
};

const GlobalStyle = createGlobalStyle`

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  z-index: 1000;
}

`;
const MyApp = () => {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <GlobalStyle />
        <App />
      </BrowserRouter>
    </ApolloProvider>
  );
};

export default MyApp;
