import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  min-width: 320px;
  /* max-width: 1200px; */
  width: 100%;
  margin-top: 2rem;
  @media only screen and (min-width: 600px) {
    padding: 1rem;
  }
`;

const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Page = ({ children }) => {
  return (
    <StyledPage>
      <Container>{children}</Container>
    </StyledPage>
  );
};
export default Page;
