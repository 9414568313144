import React from 'react';
import { useField } from 'formik';
import styled, { css } from 'styled-components';

import { Input } from '.';

const commonStyles = css`
  // remove all default styles
  font-size: inherit;
  border: none;
  outline: none;
  background: none;
`;

const errorStyle = css`
  background-color: #e85a1e;
`;

const StyledInput = styled.input`
  ${commonStyles}
  ${({ error }) => (error ? errorStyle : '')}
  line-height: 2rem;
  width: 100%;
`;

const StyledTextArea = styled.textarea`
  ${commonStyles}
  ${({ error }) => (error ? errorStyle : '')}
  line-height: 1.1rem;
  padding: 0.5rem;
  width: 100%;
  min-height: 4rem;
  display: block;
  height: auto;
`;

const SimpleTextInput = ({
  name,
  multiline,
  disabled,
  placeholder,
  error,
  onChange,
  className,
  rows = 5,
}) => {
  const [field, meta] = useField(name);
  const handleOnChange = React.useCallback((ev) => {
    onChange?.(ev);
    field.onChange(ev);
  }, []);
  return (
    <Input className={className}>
      {multiline ? (
        <StyledTextArea
          error={meta.error && meta.touched}
          placeholder={placeholder}
          rows={rows}
          {...field}
          onChange={handleOnChange}
          disabled={disabled}></StyledTextArea>
      ) : (
        <StyledInput
          error={error || (meta.error && meta.touched)}
          type="text"
          placeholder={placeholder}
          {...field}
          onChange={handleOnChange}
          disabled={disabled}
        />
      )}
    </Input>
  );
};

export default SimpleTextInput;
