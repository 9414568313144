import React from 'react';
import styled from 'styled-components';

import ActivityIndicator from './ActivityIndicator';

const StyledButton = styled.button`
  /* remove ALL default browser styling */
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  padding: 0;
  appearance: none;

  /* optional, but it's a good idea to highlight the link somehow */
  &:disabled {
    color: grey;
    cursor: not-allowed;
  }
`;

const UnstyledButton = ({
  className,
  onClick,
  children,
  disabled,
  loading,
}) => {
  const handleOnClick = React.useCallback((ev) => {
    if (onClick) {
      ev.preventDefault();
      onClick(ev);
    }
  }, []);
  return (
    <StyledButton
      className={className}
      type="button"
      onClick={handleOnClick}
      disabled={disabled}>
      {loading ? <ActivityIndicator /> : children}
    </StyledButton>
  );
};

export default UnstyledButton;
