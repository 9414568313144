import React, { memo } from 'react';

const OpmerkingTT = memo(({ diertext, lidtext }) => {
  const [active, setActive] = React.useState(false);

  let dierenopmerking;
  if (diertext?.length > 0) {
    dierenopmerking = (
      <div>
        {'DIEREN OPMERKING: '}{' '}
        <ul>
          {diertext?.map((val) => {
            return <li key={val.id}>{val.text}</li>;
          })}
        </ul>{' '}
      </div>
    );
  }
  return (
    <div className={'mytooltip' + (active ? ' showtooltip' : '')}>
      <span className="mytooltiptext">
        {lidtext ? <div>{'LID OPMERKING: ' + lidtext}</div> : null}
        {dierenopmerking}
      </span>
      <i
        className="fas fa-exclamation-triangle exclamation-click"
        onClick={() => setActive(!active)}></i>
    </div>
  );
});
export default OpmerkingTT;
