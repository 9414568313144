import React from 'react';
import { useMutation } from '@apollo/client';
import MDEditor, { commands } from '@uiw/react-md-editor';
import { useField } from 'formik';
import styled from 'styled-components';

import { REACT_APP_API_LOCATION } from '../../../config';
import { UPLOAD_FILE_SINGLE } from '../../../graphql';
import { Error, Input, Label, Wrapper } from '.';

const Info = styled.div``;

const myCommands1 = [
  commands.bold,
  commands.italic,
  commands.strikethrough,
  commands.hr,
  commands.title,
  commands.divider,
  commands.link,
  commands.quote,
  commands.code,
  commands.codeBlock,
];
const myCommands2 = [
  commands.table,
  commands.divider,
  commands.unorderedListCommand,
  commands.orderedListCommand,
  commands.divider,
  commands.help,
];

const MarkDownTextInput = ({
  name,
  label,
  info,
  placeholder,
  onChange,
  error,
}) => {
  const [field, meta, helpers] = useField({ name, type: 'textarea' });
  const handleOnChange = React.useCallback((value) => {
    onChange?.(value);
    helpers.setValue(value);
  }, []);

  const [uploadFile, { loading: loadingUploadFile }] = useMutation(
    UPLOAD_FILE_SINGLE,
  );

  const imageCommand = {
    ...commands.image,
    execute: (_, api) => {
      // append a hidden input file to the document
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.style.display = 'none';
      input.onchange = async (e) => {
        const file = e.target.files[0];
        // upload the file to the backend and then get back the url
        const result = await uploadFile({
          variables: { file: file },
        });
        const url = result.data.upload.url;
        const name = result.data.upload.name;
        const fullUrl = `${REACT_APP_API_LOCATION}${url}`;
        // append the image url to MD editor
        api.replaceSelection(`![${name}](${fullUrl})`);
        // remove the hidden input file from the document
        document.body.removeChild(input);
      };
      document.body.appendChild(input);
      input.click();
    },
  };

  return (
    <Wrapper>
      <Label htmlFor={name}>{label}</Label>
      <Input>
        {info && <Info>{info}</Info>}
        <MDEditor
          style={{ whiteSpace: 'pre-wrap' }}
          minHeight={600}
          textareaProps={{
            placeholder,
            name,
            autoCapitalize: 'on',
          }}
          commands={[...myCommands1, imageCommand, ...myCommands2]}
          {...field}
          onChange={handleOnChange}
        />
        {(error || (meta.error && meta.touched)) && (
          <Error>{error || meta.error}</Error>
        )}
      </Input>
    </Wrapper>
  );
};

export default MarkDownTextInput;
