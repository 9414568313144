import React, { useCallback } from 'react';
import { useField } from 'formik';
import styled, { css } from 'styled-components';

import { Switch as SwitchContainer } from '../BedelingView/BedelingTableHeader';
import { Label } from './input';

const Container = styled(SwitchContainer)`
  width: unset;
  ${({ hasLabel }) =>
    hasLabel &&
    css`
      line-height: 2rem;
      text-align: left;
      display: flex;
      align-items: center;
      border-bottom: 1px dotted grey;
      padding: 0.5rem 0.25rem;
      width: 100%;
      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
    `}
`;

const Switch = styled.div`
  position: relative;
  background: ${({ type }) => (type === 'been' ? '#242729' : 'navy')};
  border-radius: 0.4365em;
  height: 2em;
  color: #fff;
  display: flex;
  font-size: ${({ type }) => (type === 'been' ? '1rem' : '0.8rem')};
  width: 8.125em;

  &::after {
    content: '';
    position: absolute;
    height: 1.875em;
    border-radius: 0.3125em;
    ${({ value }) =>
      value === true
        ? 'background-color: green; border-top: 2px solid #41ff41; border-left: 1px solid #41ff41;'
        : value === false
        ? 'background-color: #d10303; border-top: 2px solid #f22; border-left: 1px solid #f22;'
        : 'background-color: grey; border-top: 2px solid #aaa9a9; border-left: 1px solid #aaa9a9;'}
    left: 0;
    ${({ value }) =>
      value === true
        ? 'transform: translateX(0%);'
        : value === false
        ? 'transform: translateX(200%);'
        : 'transform: translateX(100%);'}
    transition: transform 200ms, background-color 200ms, border 200ms;
    width: 2.625em;
  }
`;

const CommonButton = styled.div`
  border-radius: 5px;
  height: 1.875em;
  width: 42px;
  text-align: center;
  line-height: 1.875em;
  margin: 1px;
  z-index: 1;
  cursor: pointer;
`;

// type is either 'been' or 'checkPapers'
// tooltips is an object with 3 keys, true|null|false values, with their values describing each button
const ThreeWaySwitch = ({ setValue, value, type, label, name, tooltips }) => {
  const [field, , helpers] = name
    ? useField(name)
    : [{ value, onChange: () => {} }, {}, { setValue: () => {} }];
  const [_value, _setValue] = React.useState(field.value ?? value);
  const handleTrue = useCallback(() => {
    _setValue(true);
    setValue?.(true);
    helpers.setValue(true);
  }, [setValue, field]);
  const handleNull = useCallback(() => {
    _setValue(null);
    setValue?.(null);
    helpers.setValue(null);
  }, [setValue, field]);
  const handleFalse = useCallback(() => {
    _setValue(false);
    setValue?.(false);
    helpers.setValue(false);
  }, [setValue, field]);

  return (
    <Container hasLabel={Boolean(label)}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <Switch value={field.value ?? _value} type={type}>
        <CommonButton onClick={handleTrue} title={tooltips.true}>
          <i className="fas fa-check"></i>
        </CommonButton>
        <CommonButton onClick={handleNull} title={tooltips.null}>
          /
        </CommonButton>
        <CommonButton onClick={handleFalse} title={tooltips.false}>
          <i className="fas fa-times"></i>
        </CommonButton>
      </Switch>
    </Container>
  );
};

export default React.memo(ThreeWaySwitch);
