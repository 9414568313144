import React from 'react';
import { Link } from 'react-router-dom';

import { Actief, Container, Geweest, Naam, Opmerking } from './LidTableHeader';

const LidTable = ({ leden }) => {
  return (
    leden?.map((lid) => (
      <Container key={lid.id} data-textfinder={lid.textfinder}>
        <Actief className="column actief actiefcheckbox">
          {lid?.actief ? (
            <i className="fas fa-check" />
          ) : (
            <i className="fas fa-times" />
          )}
        </Actief>
        <Naam className="column name">
          <Link
            to={`/leden/edit/${lid.id}`}>{`${lid.fname} ${lid.lname}`}</Link>
        </Naam>
        <Geweest>
          {lid.ooitgeweest ? (
            <i className="fas fa-check" />
          ) : (
            <i className="fas fa-times" />
          )}
        </Geweest>
        {lid.opmerking && <Opmerking>{lid.opmerking}</Opmerking>}
      </Container>
    )) ?? <div>Geen leden</div>
  );
};

export default LidTable;
