import styled from 'styled-components';

export const Wrapper = styled.div`
  line-height: 2rem;
  text-align: left;
  display: flex;
  align-items: center;
  border-bottom: 1px dotted grey;
  padding: 0.5rem 0.25rem;
  width: 100%;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const Error = styled.div`
  color: red;
`;

export const Label = styled.label`
  min-width: 14rem;
`;

export const Input = styled.div`
  width: 100%;
`;
