import React from 'react';
import styled from 'styled-components';

import ActivityIndicator from './ActivityIndicator';

const Modal = styled.div`
  position: fixed;
  left: 50%;
  background-color: #cccccc;
  width: 150px;
  /*half the size of width*/
  margin-left: -75px;
  z-index: 999;
  text-align: center;
  border: 1px solid black;
  top: 50%;
  height: 150px;
  /*half the size of height*/
  margin-top: -75px;
  display: grid;
  justify-items: center;
  align-content: center;

  box-shadow: rgb(0 0 0 / 50%) 0.1rem 0.2rem 1rem;
`;

const LoadingDivModal = () => {
  return (
    <Modal>
      <p>Loading...</p>
      <ActivityIndicator />
    </Modal>
  );
};
export default LoadingDivModal;
