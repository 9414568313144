import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';

import { useAuth } from '../../auth/index.js';
import {
  CHECK_UNREAD_BERICHTEN,
  CREATE_BERICHTEN,
  GET_BERICHTEN,
} from '../../graphql/index.js';
import Button from '../common/Button.js';
import LoadingDivModal from '../common/LoadingDivModal';
import Page from '../common/page.js';
import PopupModal from '../common/PopupModal.js';
import TopTitle from '../common/TopTitle.js';
import { useLocalStorage } from '../Helpers.js';
import SearchBox from '../SearchBox.js';
import { MessageFilter } from './filter.jsx';
import { Message } from './message.jsx';
import { NewMessageFormContent } from './newMessageFormContent.jsx';

const TitleAndButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.5rem;
  padding-bottom: 0;
`;

const Container = styled.div`
  padding: 0.5rem;
`;

const NoMessages = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  padding: 2rem;
  font-size: 1.5rem;
  color: #666;
`;

const StyledSearchBox = styled(SearchBox)`
  margin: 1rem 0;
`;

/**
 * @property {MessageType} id - The id of the message type as defined in the backend
 */
export const messageTypes = [
  { id: 'toVulploeg', value: '@ vulploeg' },
  { id: 'toBedelingsploeg', value: '@ bedelingsploeg' },
  { id: 'toSupport', value: '@ support' },
  { id: 'fromSupport', value: 'technische melding' },
];

const initialValues = {
  message: '',
  title: '',
  type: '_default',
};

const NewMessageSchema = Yup.object().shape({
  type: Yup.string()
    .oneOf(
      messageTypes.map((type) => type.id),
      'Type is verplicht',
    )
    .required('Type is verplicht'),
  title: Yup.string().required('Titel is verplicht'),
  message: Yup.string().required('Bericht is verplicht'),
});

const MessagePage = () => {
  const { currentUser } = useAuth();
  const [searchString, setSearchString] = React.useState('');

  const [showNewMessageModal, setShowNewMessageModal] = React.useState(false);

  const [selectedMessageType, setSelectedMessageType] =
    useLocalStorage('berichtenType');
  const [selectedMessageRead, setSelectedMessageRead] =
    useLocalStorage('berichtenRead');

  const filter = {
    type: selectedMessageType,
    read: selectedMessageRead,
  };

  const setFilter = (newFilter) => {
    setSelectedMessageType(newFilter.type ?? '_default');
    setSelectedMessageRead(newFilter.read ?? '_default');
  };

  const filterReadValue =
    selectedMessageRead === 'read'
      ? {
          readByUsers: currentUser?.id,
        }
      : selectedMessageRead === 'unread'
      ? {
          _or: [
            { readByUsers_null: true },
            { readByUsers_ncontains: currentUser?.id }, // THIS WILL NOT ALWAYS WORK, need to still filter client-side
          ],
        }
      : {};

  const searchValueFilter = searchString
    ? {
        _or: [
          { title_contains: searchString },
          { richBody_contains: searchString },
        ],
      }
    : {};

  const { loading: loadingBerichten, data: dataBerichten } = useQuery(
    GET_BERICHTEN,
    {
      pollInterval: 5000,
      variables: {
        where: {
          _sort: 'created_at:DESC',
          type:
            selectedMessageType === '_default'
              ? undefined
              : selectedMessageType,
          _and: [filterReadValue, searchValueFilter],
        },
      },
    },
  );

  const [createMessage, { loading: loadingCreateMessage }] = useMutation(
    CREATE_BERICHTEN,
    {
      refetchQueries: [
        { query: GET_BERICHTEN },
        { query: CHECK_UNREAD_BERICHTEN },
      ],
      onError: (error) => {
        console.error(error);
      },
      onCompleted: () => {
        setShowNewMessageModal(false);
      },
    },
  );

  const handleCreateMessage = async (values) => {
    await createMessage({
      variables: {
        input: {
          title: values.title,
          richBody: values.message,
          type: values.type,
        },
      },
    });
  };

  // Filter messages based on the selected filter
  // this is done client-side because the backend has a bug when filtering on readByUsers_ncontains
  // bonus: by doing it client-side as well. toggling between read and unread is instant (when filtered)
  const filteredBerichten = dataBerichten?.berichtens.filter((messageData) => {
    if (filter.read === '_default') return true;
    if (filter.read === 'read') {
      return messageData.readByUsers.some(
        (user) => user.id === currentUser?.id,
      );
    }
    if (filter.read === 'unread') {
      return !messageData.readByUsers.some(
        (user) => user.id === currentUser?.id,
      );
    }
    return true;
  });

  return (
    <Page>
      <TitleAndButton>
        <TopTitle>Berichten</TopTitle>
        <Button primary onClick={() => setShowNewMessageModal(true)}>
          Maak Nieuw...
        </Button>
        <MessageFilter
          setSelectedMessageType={setSelectedMessageType}
          selectedMessageType={selectedMessageType}
          filter={filter}
          setFilter={setFilter}
        />
        <StyledSearchBox
          setSearchString={setSearchString}
          searchString={searchString}
          loading={loadingBerichten}
        />
      </TitleAndButton>
      <Container>
        {loadingBerichten && <LoadingDivModal />}
        {filteredBerichten?.map((messageData, i) => (
          <Message key={i} messageData={messageData} />
        ))}
        {!loadingBerichten && filteredBerichten?.length === 0 && (
          <NoMessages>
            <div>Geen berichten gevonden</div>
            {selectedMessageType !== '_default' && (
              <div>Pas eventueel uw filter aan, indien nodig</div>
            )}
          </NoMessages>
        )}
      </Container>
      <PopupModal
        width="90%"
        canClose
        visible={showNewMessageModal}
        setPopup={setShowNewMessageModal}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleCreateMessage}
          validationSchema={NewMessageSchema}
          validateOnMount>
          <NewMessageFormContent loading={loadingCreateMessage} />
        </Formik>
      </PopupModal>
    </Page>
  );
};

export default MessagePage;
