import React, { useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import styled, { css } from 'styled-components';

import { CHECK_UNREAD_BERICHTEN } from '../graphql';

const Wrapper = styled.div`
  overflow: hidden;
  display: flex;
  z-index: 9999;
  /* max-width: 1200px; */
  background-color: #333;

  width: 100%;
  @media screen and (max-width: 600px) {
    display: block;
    position: absolute;
    width: 100%;
  }
`;

const StyledNavLink = styled(NavLink)`
  display: block;
  color: #f2f2f2 !important;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  cursor: pointer;

  &:hover {
    background-color: #ddd;
    color: black !important;
  }
  &.active {
    background-color: rgb(71, 211, 76);
    color: black !important;
  }

  @media screen and (max-width: 600px) {
    display: ${({ $showTopNav }) => ($showTopNav ? 'block' : 'none')};
    text-align: left;
  }
`;

const Container = styled.div`
  width: 100%;
  background-color: #333;
  @media screen and (min-width: 600px) {
    display: grid;
    place-items: center;
  }
`;

const MenuButton = styled.button`
  @media screen and (max-width: 600px) {
  }
  display: none;
  border: none;
  background: transparent;
  outline: 0;

  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  cursor: pointer;

  &:focus {
    border: none;
    background: transparent;
    outline: 0;
  }

  @media screen and (max-width: 600px) {
    display: block;
    ${({ showTopNav }) =>
      showTopNav &&
      css`
        position: relative;
        & .icon {
          position: absolute;
          right: 0;
          top: 0;
        }
        & a {
          display: block;
          text-align: left;
        }
      `}
  }
`;

const HomeLink = styled(StyledNavLink)`
  display: block;
  flex-grow: ${({ showTopNav }) => (showTopNav ? 1 : 0)};
  position: relative;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
`;

const UnreadMessagesIndicator = styled.div`
  background-color: #e85a1e;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 100%;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
`;

const Nav = () => {
  const [showTopNav, setShowTopNav] = useState(false);
  const toggleTopNav = useCallback(() => {
    setShowTopNav((prev) => !prev);
  }, []);
  const hideTopNav = useCallback(() => {
    setShowTopNav(false);
  }, []);

  const { data: unreadMessagesData } = useQuery(CHECK_UNREAD_BERICHTEN, {
    pollInterval: 5000,
  });

  const unreadMessagesCount = unreadMessagesData?.checkUnreadBerichten.count;

  return (
    <Container>
      <Wrapper>
        <Top>
          <HomeLink
            exact
            to="/messages"
            onClick={hideTopNav}
            $showTopNav={showTopNav}>
            Berichten
            {unreadMessagesCount > 0 && <UnreadMessagesIndicator />}
          </HomeLink>
          <MenuButton name="menu" onClick={toggleTopNav}>
            <i className="fa fa-bars"></i>
          </MenuButton>
        </Top>
        <StyledNavLink
          activeClassName="active"
          to="/leden"
          onClick={hideTopNav}
          $showTopNav={showTopNav}>
          Leden
        </StyledNavLink>
        <StyledNavLink
          activeClassName="active"
          to="/bedelingen"
          onClick={hideTopNav}
          $showTopNav={showTopNav}>
          Bedelingen
        </StyledNavLink>
        <StyledNavLink
          activeClassName="active"
          to="/dieren"
          onClick={hideTopNav}
          $showTopNav={showTopNav}>
          Dieren
        </StyledNavLink>

        <StyledNavLink
          activeClassName="active"
          to="/instellingen"
          onClick={hideTopNav}
          $showTopNav={showTopNav}>
          Instellingen
        </StyledNavLink>
      </Wrapper>
    </Container>
  );
};

export default Nav;
