import React from 'react';
import { useCallback, useState } from 'react';
import styled from 'styled-components';

import { Label, Wrapper } from './input';

const StyledButton = styled.button`
  margin-left: 1rem;
`;

const ListWrapper = styled.div`
  width: 100%;
  padding: 0;
`;

const ListItem = styled.li`
  line-height: 1em;
`;

const HideableList = ({ label, className, items }) => {
  const [showing, setShowing] = useState(false);

  const toggleShowing = useCallback(() => {
    setShowing((prev) => !prev);
  }, []);

  return (
    <Wrapper className={className}>
      <Label>{label}</Label>
      <ListWrapper>
        {items?.length > 0 ? (
          <>
            <i className="fas fa-check"></i>
            <StyledButton onClick={toggleShowing} type="button">
              {showing ? 'Verberg Lijst' : 'Toon Lijst'}
            </StyledButton>
          </>
        ) : (
          <i className="fas fa-times"></i>
        )}
        {showing && (
          <ul>
            {items?.map((item) => (
              <ListItem key={item.id}>{item.value}</ListItem>
            ))}
          </ul>
        )}
      </ListWrapper>
    </Wrapper>
  );
};

export default HideableList;
