import React from 'react';
import styled from 'styled-components';

const Title = styled.h2`
  padding: 1rem;
`;

const TopTitle = ({ children }) => {
  return <Title>{children}</Title>;
};

export default TopTitle;
