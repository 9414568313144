import React from 'react';
import styled from 'styled-components';

const StyledRow = styled.div`
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: ${({ center })=>center?'center':'space-between'};
  @media only screen and (min-width: 600px) {
    flex-direction: row;
  }
`;

const Row = ({ children, center, className }) => {
  return <StyledRow center={center} className={className}>{children}</StyledRow>
};

export default Row;
