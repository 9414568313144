import { useEffect, useState } from 'react';
const useContextMenu = (handleItemClick) => {
  const [isOpened, setOpened] = useState(false);
  const [points, setPoints] = useState({
    x: 0,
    y: 0,
  });
  const onItemClick = (ev) => {
    handleItemClick?.(ev.target.dataset.action);
    setOpened(false);
  };
  const handleOutsideClick = () => {
    setOpened(false);
  };
  const open = () => {
    setOpened(true);
  };
  useEffect(() => {
    if (!isOpened) {
      return;
    }

    document.addEventListener('click', handleOutsideClick);
    document.addEventListener('contextmenu', handleOutsideClick);
    return () => {
      document?.removeEventListener('click', handleOutsideClick);
      document?.removeEventListener('contextmenu', handleOutsideClick);
    };
  }, [isOpened, handleOutsideClick, document]);
  return {
    isOpened,
    open,
    points,
    setPoints,
    onItemClick,
  };
};
export default useContextMenu;
