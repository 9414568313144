import React from 'react';
import styled from 'styled-components';

import ActivityIndicator from './ActivityIndicator';

const StyledButton = styled.button`
  text-align: center;
  background-color: ${({ primary, secondary, tertiary, disabled }) =>
    disabled
      ? 'grey'
      : primary
      ? '#4caf50'
      : secondary
      ? '#FF0000'
      : tertiary
      ? 'navy'
      : 'grey'};
  color: white;
  padding: 1em 2em;
  border: none;
  border-radius: 4px;
  margin: 0.5rem;
  font-size: 1rem;
  white-space: nowrap;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: center;
  & i {
    font-size: 1.3em;
    margin-right: 0.75em;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ stretch }) => (stretch ? 'stretch' : 'flex-start')};
`;

const DisabledReason = styled.div`
  color: red;
  font-size: 1rem;
  font-weight: bold;
`;

const Button = ({
  primary,
  secondary,
  className,
  onClick,
  children,
  tertiary,
  disabled,
  disabledReason,
  loading,
}) => {
  const handleOnClick = React.useCallback((ev) => {
    if (onClick) {
      ev.preventDefault();
      onClick(ev);
    }
  }, []);
  return (
    <Wrapper stretch={!disabledReason}>
      {disabledReason && disabled && (
        <DisabledReason>{disabledReason}</DisabledReason>
      )}
      <StyledButton
        primary={primary}
        secondary={secondary}
        tertiary={tertiary}
        type="button"
        className={className}
        onClick={handleOnClick}
        disabled={disabled}>
        {loading ? <ActivityIndicator /> : children}
      </StyledButton>
    </Wrapper>
  );
};

export default Button;
