import * as React from 'react';
import Modal from 'react-modal';
import OutsideClickHandler from 'react-outside-click-handler';
import styled from 'styled-components';

import { boxShadowCss } from './box';
import Button from './Button';

// makes the buttons stretch nicely to the model width
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 4rem;
  gap: 4rem;
  & > * {
    width: 100%;
  }
  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

// gives some margin inbetween children
const Container = styled.div`
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

const StyledHeading1 = styled.div`
  text-align: center;
  color: #e85a1e;
  font-size: 1.5rem;
  font-weight: bold;
`;

const StyledModal = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${({ width }) => width ?? '40rem'};
  background-color: white;
  border: 8px solid #e85a1e;
  border-radius: 1rem;
  ${boxShadowCss}

  transform-origin: center center;
  /* Fade in animation */
  animation: fade-in 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) both;
  @keyframes fade-in {
    0% {
      transform: translate(-50%, -50%) scale(0);
      opacity: 0;
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  }
`;

const ChildrenWrapper = styled.div`
  color: black;
  white-space: pre-line;
  font-size: 1.1rem;
`;

const PopupModal = ({
  onConfirmation,
  setPopup,
  children,
  customButtons,
  visible,
  className,
  title,
  width,
  onOutsideClick = () => {
    setPopup(false);
  },
}) => {
  const closePopup = React.useCallback(() => {
    setPopup(false);
  }, [setPopup]);

  const handleConfirm = React.useCallback(() => {
    setPopup(false);
    if (onConfirmation) {
      onConfirmation();
    }
  }, [setPopup, onConfirmation]);

  return (
    <StyledModal isOpen={visible} className={className} width={width}>
      <OutsideClickHandler onOutsideClick={onOutsideClick}>
        <Container>
          <StyledHeading1>{title}</StyledHeading1>
          <ChildrenWrapper>{children}</ChildrenWrapper>
          {customButtons || (
            <ButtonsWrapper>
              <Button onClick={handleConfirm} secondary>
                Ja
              </Button>
              <Button onClick={closePopup} primary>
                Nee
              </Button>
            </ButtonsWrapper>
          )}
        </Container>
      </OutsideClickHandler>
    </StyledModal>
  );
};
PopupModal.displayName = 'PopupModal';

export default React.memo(PopupModal);
