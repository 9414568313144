import React from 'react';


export default class NotFound extends React.Component {
  render() {    
    return (
        <div>
            Error 404, page not found.
      </div>
    );
  }
}