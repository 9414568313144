import React from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';

import SelectInput from '../common/input/select';
import { messageTypes } from '.';

const FilterWrapper = styled.div`
  position: relative;
  border: ${({ hasSelected }) =>
    hasSelected ? '2px dashed #e85a1e' : '1px dashed black'};
  padding: 1rem;

  &::before {
    content: '${({ title }) => title}';
    position: absolute;
    top: 0;
    left: 1rem;
    font-size: 1rem;
    transform: translateY(-50%);
    background-color: white;
    padding: 0 0.5rem;
  }
`;

export const MessageFilter = ({ setFilter, filter }) => {
  return (
    <Formik initialValues={filter}>
      <FilterWrapper
        title="filter"
        hasSelected={filter.type !== '_default' || filter.read !== '_default'}>
        <SelectInput
          hasAllOption
          name="type"
          label="type"
          values={messageTypes}
          onChange={(value) => {
            setFilter({ ...filter, type: value });
          }}
        />
        <SelectInput
          hasAllOption
          name="read"
          label="gelezen/ongelezen"
          values={[
            { id: 'read', value: 'Gelezen' },
            { id: 'unread', value: 'Ongelezen' },
          ]}
          onChange={(value) => setFilter({ ...filter, read: value })}
        />
      </FilterWrapper>
    </Formik>
  );
};
