import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import { GET_LEDEN } from '../../graphql/index.js';
import Button from '../common/Button';
import LoadingDiv from '../common/LoadingDivModal';
import Page from '../common/page.js';
import TopTitle from '../common/TopTitle.js';
import LocatieFilter from '../LocatieFilter';
import SearchBox from '../SearchBox';
import LidTable from './LidTable.js';
import LidTableHeader from './LidTableHeader.js';

const SearchAndButton = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const TitleAndFilter = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 600px) {
    flex-direction: row;

    justify-content: space-between;
  }
`;

const Table = styled.div`
  margin-top: 1rem;
  margin-bottom: 5rem;
`;

const Leden = ({
  locations: { userLocations, locationToFilter, changeLocation },
  location,
}) => {
  const [searchString, setSearchString] = React.useState('');
  const [leden, setLeden] = React.useState([]);

  const { data: dataLeden, loading: loadingLeden } = useQuery(GET_LEDEN, {
    // pollInterval: 5000,
  });

  const ledenBackup = React.useMemo(() => {
    const nieuwLeden = dataLeden?.ledens.map((_lid) => {
      const lid = { ..._lid };
      const ooitgeweest =
        lid?.aanwezigheden?.filter((x) => x?.aanwezig)?.length > 0;
      const actieftext = lid.actief
        ? 'wel actief:ja'
        : 'inactief niet actief:nee';
      const dierennamen = lid?.huisdieren
        .map((x) => x?.naam)
        .reduce((acc, x) => `${acc} ${x}`, '');
      const dierenopmerkingen = lid?.huisdieren
        ?.filter((x) => x?.opmerking)
        .reduce((acc, x) => `${acc}<br/>-${x?.naam}: ${x?.opmerking}; `, '');
      const nonetext = [
        lid?.address,
        lid?.postcode,
        lid?.city,
        lid?.email,
        lid?.sinds,
        lid?.bevestigd,
      ]
        .map((x) => x || '???')
        .join(' ');
      const geweesttext = ooitgeweest ? 'al geweest:ja' : 'nooit geweest:nee';
      const dierentypes = [
        ...new Set(
          lid?.huisdieren.map((dier) => {
            const typeKort = dier.type === 5 ? dier.andere : dier.type?.kort;
            const leeftijd = dier.andere ? '' : dier.leeftijd?.kort;

            return (
              dier.andere ?? `${leeftijd}${typeKort}; ${leeftijd} ${typeKort}`
            );
          }),
        ),
      ].join('; ');
      const textfinder = `all;${lid.phoneNumber};${nonetext};${lid.fname} ${lid.lname};${lid.lname} ${lid.fname};${lid.address};${dierennamen};${dierenopmerkingen};${lid.opmerking};${actieftext};${geweesttext};${lid.email};${lid.situatie};${dierentypes}`;
      lid.textfinder = textfinder.toLowerCase();
      lid.ooitgeweest = ooitgeweest;
      return lid;
    });
    return nieuwLeden;
  }, [dataLeden]);

  React.useEffect(() => {
    const newLeden =
      locationToFilter && userLocations?.length !== 1
        ? ledenBackup?.filter((x) => x.locatie.id == locationToFilter) // == by design
        : ledenBackup?.slice();
    searchString
      ? setLeden(
          newLeden?.filter((x) =>
            searchString
              .split(';')
              .every((s) => x?.textfinder?.indexOf(s.toLowerCase()) >= 0),
          ),
        )
      : setLeden(newLeden);
  }, [ledenBackup, locationToFilter, searchString, userLocations]);

  // use function below if we decide to reset search when changing location
  // const handlechangeLocation = React.useCallback(
  //   (locID) => {
  //     setSearchString('');
  //     changeLocation(locID);
  //   },
  //   [changeLocation],
  // );

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location?.search);
    for (const [key, value] of searchParams) {
      switch (key) {
        case 'search':
          if (!searchString) setSearchString(value);
          return;
        default:
          return;
      }
    }
  }, [location]);

  const history = useHistory();
  const openNewLid = useCallback(() => {
    window.open('/leden/new', '_blank');
  }, [history]);

  return (
    <Page>
      <TitleAndFilter>
        <TopTitle>LEDEN</TopTitle>
        <LocatieFilter
          userLocations={userLocations}
          locationToFilter={locationToFilter}
          changeLocation={changeLocation}
        />
      </TitleAndFilter>
      <SearchAndButton>
        <SearchBox
          setSearchString={setSearchString}
          searchString={searchString}
        />
        <Button primary onClick={openNewLid}>
          Maak Nieuw...
        </Button>
      </SearchAndButton>
      <Table>
        <LidTableHeader />
        {loadingLeden && <LoadingDiv />}
        <LidTable leden={leden} />
      </Table>
    </Page>
  );
};
export default Leden;
