import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';

import { extractApolloErrorMessage, getAuthToken, logout } from '../auth';
import { REACT_APP_API_LOCATION } from '../config';

const httpLink = createHttpLink({
  uri: `${REACT_APP_API_LOCATION}/graphql`,
});

const uploadLink = createUploadLink({
  uri: `${REACT_APP_API_LOCATION}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const authToken = getAuthToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      authorization: authToken ? `Bearer ${authToken}` : '',
      ...headers,
    },
  };
});

const errorLink = onError((error) => {
  const authToken = getAuthToken();
  const msg = extractApolloErrorMessage(error);
  console.error('error message extracted :>> ', msg);
  console.error(error);
  // We're implicitly logged out
  if (['Invalid token.'].includes(msg) && authToken) {
    console.log('logging out');
    logout();
    window.location.href = '/';
  }
});
export const client = new ApolloClient({
  link: from([errorLink, authLink, uploadLink, httpLink]),
  cache: new InMemoryCache(),
});
