import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  align-items: center;
  grid-template-areas:
    'actief naam geweest'
    'actief opmerking geweest';
  grid-template-columns: 1fr 5fr 1fr;
  border-bottom: 3px solid #666;
  min-height: 3rem;

  @media only screen and (min-width: 600px) {
    grid-template-areas: 'actief naam geweest opmerking';
    grid-template-columns: 1fr 3fr 1fr 10fr;
    border-bottom: 2px solid #666;
    min-height: 2rem;
  }
`;

const Header = styled(Container)`
  background-color: #ffeedb;
`;

const Item = styled.div`
  text-align: center;
  width: 100%;
  /* line-height: 2; */
  @media only screen and (min-width: 600px) {
    /* line-height: 3; */
  }
`;

export const Actief = styled(Item)`
  grid-area: actief;
`;

export const Naam = styled(Item)`
  grid-area: naam;
`;

export const Geweest = styled(Item)`
  grid-area: geweest;
`;

export const Opmerking = styled(Item)`
  grid-area: opmerking;
`;

const LidTableHeader = () => {
  return (
    <Header>
      <Actief>Actief?</Actief>
      <Naam>Naam</Naam>
      <Geweest>geweest?</Geweest>
      <Opmerking>Opmerkingen</Opmerking>
    </Header>
  );
};

export default LidTableHeader;
