import { useEffect, useState } from 'react';
const LOCATIONFILTER = 'LOCATIONFILTER';

export const useLocations = () => {
  const [locationToFilter, setLocationToFilter] = useState(
    JSON.parse(localStorage.getItem(LOCATIONFILTER) ?? 0),
  );

  const [userLocations, setUserLocations] = useState([]);

  const changeLocation = (newLocId) => {
    setLocationToFilter(newLocId);
    localStorage.setItem(LOCATIONFILTER, parseInt(newLocId, 10));
  };

  useEffect(() => {
    if (localStorage.getItem(LOCATIONFILTER) === null) {
      changeLocation(0);
    }
  }, [locationToFilter]);

  return { locationToFilter, changeLocation, setUserLocations, userLocations };
};

export const useLocalStorage = (key, defaultValue = '_default') => {
  const [filter, _setFilter] = useState(
    JSON.parse(localStorage.getItem(key)) ?? defaultValue,
  );

  const setFilter = (newValue) => {
    _setFilter(newValue);
    localStorage.setItem(key, JSON.stringify(newValue));
  };

  useEffect(() => {
    if (localStorage.getItem(key) === null) {
      setFilter(defaultValue);
    }
  }, [key, defaultValue]);

  return [filter, setFilter];
};
