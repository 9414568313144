import './css/App.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import Modal from 'react-modal';

import MyApp from './App';

const rootEl = document.getElementById('root');
Modal.setAppElement(rootEl); // used for accessibility, see https://reactcommunity.org/react-modal/accessibility/

const root = createRoot(rootEl); // createRoot(container!) if you use TypeScript
root.render(<MyApp />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
