import { gql } from '@apollo/client';

/*
 *
 * Some graphql results are filtered
 * the results will only contain values based on the location(s) appointed to the logged-in user *
 *
 */

// ==== public calls, return everything ====

export const GET_TYPES = gql`
  query getTypes {
    types {
      id
      omschrijving
      kort
    }
  }
`;
export const GET_ANDERE_TYPES = gql`
  query getAndereTypes {
    andereTypes {
      id
      omschrijving
    }
  }
`;

export const GET_LOCATIES = gql`
  query getLocaties {
    locaties {
      id
      Naam
    }
  }
`;

export const GET_LEEFTIJDEN = gql`
  query getLeeftijden {
    leeftijds {
      id
      kort
      omschrijving
    }
  }
`;

export const POST_LOGIN = gql`
  mutation login($input: UsersPermissionsLoginInput!) {
    login(input: $input) {
      jwt
      user {
        id
        confirmed
        role {
          name
        }
      }
    }
  }
`;

export const GET_TLL = gql`
  query getTll {
    tll {
      types {
        id
        omschrijving
        kort
      }
      locaties {
        id
        Naam
      }
      leeftijds {
        id
        kort
        omschrijving
      }
      andereTypes {
        id
        omschrijving
      }
    }
  }
`;

export const CREATE_LID = gql`
  mutation createLid($data: LedenInput!) {
    createLeden(input: { data: $data }) {
      leden {
        id
      }
    }
  }
`;

// ==== private calls, must be logged in, return only what applies to you ====

export const GET_BEDELING_LID_AANWEZIGHEDEN = gql`
  query getAanwezigheden($bedelingId: ID, $lidId: ID) {
    aanwezighedens(where: { bedeling: $bedelingId, lid: $lidId, _limit: -1 }) {
      id
      aanwezig
      opmerking
      isOpmerkingVerborgen
      bedeling {
        datum
        id
      }
      lid {
        id
      }
    }
  }
`;

export const GET_LEDEN = gql`
  query getLeden {
    ledens {
      id
      actief
      locatie {
        id
      }
      huisdieren {
        id
        leeftijd {
          id
          kort
        }
        type {
          kort
          id
        }
        andere
        naam
        opmerking
        papersChecked
      }
      address
      postcode
      city
      email
      phoneNumber
      sinds
      bevestigd
      fname
      lname
      opmerking
      situatie
      aanwezigheden {
        id
        aanwezig
        opmerking
        bedeling {
          id
        }
      }
      waarborgPotten
    }
  }
`;

export const GET_LID = gql`
  query getLid($id: ID!) {
    leden(id: $id) {
      id
      actief
      locatie {
        id
        Naam
      }
      waarborgPotten
      huisdieren {
        id
        leeftijd {
          id
          kort
          omschrijving
        }
        type {
          kort
          id
          omschrijving
        }
        andere
        naam
        opmerking
        papersChecked
      }
      address
      postcode
      city
      phoneNumber
      email
      sinds
      bevestigd
      fname
      lname
      opmerking
      situatie
      bestanden {
        id
        name
        url
      }
      aanwezigheden {
        id
        aanwezig
        bedeling {
          id
          datum
        }
      }
    }
  }
`;

export const UPDATE_LID = gql`
  mutation updateLid($id: ID!, $data: editLedenInput!) {
    updateLeden(input: { where: { id: $id }, data: $data }) {
      leden {
        id
        actief
        locatie {
          id
          Naam
        }
        waarborgPotten
        huisdieren {
          id
          leeftijd {
            id
            kort
            omschrijving
          }
          type {
            kort
            id
            omschrijving
          }
          andere
          naam
          opmerking
          papersChecked
        }
        address
        postcode
        city
        email
        sinds
        bevestigd
        phoneNumber
        fname
        lname
        opmerking
        situatie
        bestanden {
          id
          name
          url
        }
        aanwezigheden {
          id
          aanwezig
          bedeling {
            id
            datum
          }
        }
      }
    }
  }
`;

export const UPDATE_DIER = gql`
  mutation updateDier($id: ID!, $data: editHuisdierenInput!) {
    updateHuisdieren(input: { where: { id: $id }, data: $data }) {
      huisdieren {
        id
        leeftijd {
          id
          kort
          omschrijving
        }
        type {
          kort
          id
          omschrijving
        }
        andere
        naam
        opmerking
        papersChecked
      }
    }
  }
`;

export const CREATE_DIER = gql`
  mutation createDier($data: HuisdierenInput!) {
    createHuisdieren(input: { data: $data }) {
      huisdieren {
        id
      }
    }
  }
`;

export const DELETE_DIER = gql`
  mutation deleteDier($id: ID!) {
    deleteHuisdieren(input: { where: { id: $id } }) {
      huisdieren {
        id
      }
    }
  }
`;

export const DELETE_LID = gql`
  mutation deleteLid($id: ID!) {
    deleteLeden(input: { where: { id: $id } }) {
      leden {
        id
      }
    }
  }
`;

export const GET_ME = gql`
  query getMe {
    me {
      id
      username
      email
      role {
        id
        name
      }
      user {
        locaties {
          id
        }
      }
    }
  }
`;

export const UPDATE_ME = gql`
  mutation updateMe($email: string!) {
    updateMe(input: { email: $email }) {
      id
      username
      email
      role {
        id
        name
      }
      user {
        locaties {
          id
        }
      }
    }
  }
`;

export const GET_BEDELINGEN = gql`
  query getBedelingen {
    bedelingens {
      id
      datum
      aantalActief
      locatie {
        id
        Naam
        Actief
      }
      aanwezigheden {
        aanwezig
      }
    }
  }
`;

export const GET_BEDELING = gql`
  query getBedeling($id: ID!) {
    bedelingen(id: $id) {
      id
      locatie {
        id
      }
      datum
    }
  }
`;

export const SET_AANWEZIG = gql`
  mutation setAanwezigheden(
    $lidId: ID!
    $bedelingId: ID!
    $aanwezig: Boolean
    $opmerking: String
    $isOpmerkingVerborgen: Boolean
  ) {
    setAanwezig(
      lidId: $lidId
      bedelingId: $bedelingId
      aanwezig: $aanwezig
      opmerking: $opmerking
      isOpmerkingVerborgen: $isOpmerkingVerborgen
    ) {
      aanwezigheid {
        id
        aanwezig
        opmerking
        isOpmerkingVerborgen
        bedeling {
          id
          datum
          aantalActief
          locatie {
            id
            Naam
            Actief
          }
        }
      }
      lid {
        id
        actief
      }
    }
  }
`;

export const GET_DIEREN = gql`
  query getDieren {
    huisdierens {
      id
      naam
      baasje {
        actief
        locatie {
          id
        }
      }
      type {
        id
        kort
      }
      andere
      leeftijd {
        id
        kort
        omschrijving
      }
      papersChecked
    }
  }
`;

export const GET_BERICHTEN = gql`
  query getBerichten($where: JSON) {
    berichtens(where: $where) {
      id
      title
      richBody
      type
      isValid
      readByUsers {
        id
        username
      }
      fromUser {
        id
        username
      }
      created_at
    }
  }
`;

export const CHECK_UNREAD_BERICHTEN = gql`
  query checkUnreadBerichten {
    checkUnreadBerichten {
      count
    }
  }
`;

export const SET_BERICHT_READ = gql`
  mutation updateRead($messageId: ID!) {
    updateRead(id: $messageId) {
      id
      title
      richBody
      isValid
      type
      readByUsers {
        id
        username
      }
      fromUser {
        id
        username
      }
      created_at
    }
  }
`;

export const CREATE_BERICHTEN = gql`
  mutation createBerichten($input: BerichtenInput!) {
    createBerichten(input: { data: $input }) {
      berichten {
        id
        title
        richBody
        type
        isValid
        readByUsers {
          id
          username
        }
        fromUser {
          id
          username
        }
        created_at
      }
    }
  }
`;

export const UPLOAD_FILE_SINGLE = gql`
  mutation uploadFileSingle($file: Upload!) {
    upload(file: $file) {
      id
      name
      url
    }
  }
`;
