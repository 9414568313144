import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  --lds-size: ${({ size }) => size}px;
  height: calc(var(--lds-size) / 2);
  width: calc(var(--lds-size) * 2.5);
  & div {
    position: absolute;
    width: calc(var(--lds-size) / 2);
    height: calc(var(--lds-size) / 2);
    border-radius: 50%;
    background: #000;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  & div:nth-child(1) {
    left: 0;
    animation: lds-ellipsis1 0.6s infinite;
  }
  & div:nth-child(2) {
    left: 0;
    animation: lds-ellipsis2 0.6s infinite;
  }
  & div:nth-child(3) {
    left: var(--lds-size);
    animation: lds-ellipsis2 0.6s infinite;
  }
  & div:nth-child(4) {
    left: calc(2 * var(--lds-size));
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(var(--lds-size), 0);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
`;

const ActivityIndicator = ({ size = 24 }) => {
  return (
    <Wrapper size={size}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Wrapper>
  );
};
export default ActivityIndicator;
