import React from 'react';

const DeletingDivModal = ({ deleting, setDeleting, handleDelete }) => {
  const clearDeleting = React.useCallback(() => {
    setDeleting(null);
  }, [setDeleting]);

  return (
    <div id="delconf">
      <div className="delconf-inner">
        <i className="fas fa-exclamation-triangle"></i>
        <strong> BEN JE ZEKER DAT JE {deleting.descr} WIL VERWIJDEREN??</strong>
        <br />
        <input
          type="submit"
          onClick={clearDeleting}
          className="bottombutton cancel"
          value="NEE"></input>
        <input
          type="submit"
          onClick={handleDelete}
          className="bottombutton deletebutton final"
          value="JA (GEEN TERUGWEG!)"></input>
      </div>
    </div>
  );
};

export default DeletingDivModal;
