import React from 'react';
const ScrollButton = () => {
  const [scrolling, setScrolling] = React.useState(false);

  const _scrollFunction = React.useCallback(() => {
    //let mybutton = document.getElementById("naarBovenBtn");
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  }, []);

  // When the user clicks on the button, scroll to the top of the document
  const _naarBoven = React.useCallback(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, []);

  React.useEffect(() => {
    window.addEventListener('scroll', _scrollFunction);
  }, [_scrollFunction]);

  return (
    scrolling && (
      <button onClick={_naarBoven} id="naarBovenBtn" title="Terug naar Boven">
        <i
          className={
            scrolling ? 'fas fa-arrow-to-top' : 'fas fa-arrow-to-top'
          }></i>
      </button>
    )
  );
};

export default ScrollButton;
