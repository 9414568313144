import React from 'react';
import { useField } from 'formik';
import styled from 'styled-components';

import { Error, Input, Label, Wrapper } from '.';

const StyledSelect = styled.select`
  min-width: 15rem;
  border-radius: 0.3rem;
  border: 1px solid grey;
  padding: 0.5rem;
  font-size: 1rem;
`;

const SelectInput = ({
  name,
  label,
  values,
  defaultDisabled,
  onChange,
  hasAllOption,
  chooseValue = 'Kies ...',
}) => {
  const [field, meta] = useField(name);
  const handleOnChange = React.useCallback(
    (event) => {
      field.onChange(event);
      onChange?.(event.target.value);
    },
    [onChange],
  );
  return (
    <>
      {label ? (
        <Wrapper>
          <Label htmlFor={name}>{label}</Label>
          <Input>
            <StyledSelect name={name} {...field} onChange={handleOnChange}>
              {(hasAllOption || defaultDisabled) && (
                <option value="_default" disabled={defaultDisabled}>
                  {hasAllOption ? 'Alle' : chooseValue}
                </option>
              )}
              {values?.map((value) => {
                return (
                  <option key={value.id} value={value.id}>
                    {value.value}
                  </option>
                );
              })}
            </StyledSelect>
            {meta.error && meta.touched && <Error>{meta.error}</Error>}
          </Input>
        </Wrapper>
      ) : (
        <Input>
          <StyledSelect
            name={name}
            {...field}
            value={field.value ?? '_default'}
            onChange={handleOnChange}>
            {(hasAllOption || defaultDisabled) && (
              <option value="_default" disabled={defaultDisabled}>
                {hasAllOption ? 'Alle' : 'Andere ...'}
              </option>
            )}

            {values?.map((value) => {
              return (
                <option key={value.id} value={value.id}>
                  {value.value}
                </option>
              );
            })}
          </StyledSelect>
          {meta.error && meta.touched && <Error>{meta.error}</Error>}
        </Input>
      )}
    </>
  );
};

export default SelectInput;
