import React, { useCallback, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Form, Formik, useFormikContext } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';

import {
  CREATE_DIER,
  DELETE_DIER,
  DELETE_LID,
  GET_LID,
  GET_TLL,
  UPDATE_DIER,
  UPDATE_LID,
  UPLOAD_FILE_SINGLE,
} from '../graphql/index';
import Box from './common/box';
import Button from './common/Button';
import DeletingDivModal from './common/DeletingDivModal';
import Files from './common/Files';
import HideableList from './common/hideableList';
import Checkbox from './common/input/checkbox';
import RadioButtons from './common/input/radiobuttons';
import RadioButtonsWithOther from './common/input/radiobuttonsWithOther';
import Select from './common/input/select';
import TextInput from './common/input/textInput';
import LoadingDivModal from './common/LoadingDivModal';
import Page from './common/page';
import RouteLeavingGuard from './common/RouteLeavingGuard';
import Row from './common/Row';
import SavedModal, { showSavedModalBriefly } from './common/SavedModal';
import ThreeWaySwitch from './common/ThreeWaySwitch';

const StyledRow = styled(Row)`
  margin: 2rem 0;
`;

const LidSchema = Yup.object().shape({
  fname: Yup.string().required('Voornaam is verplicht'),
  email: Yup.string()
    .email('Geen geldig e-mail adres')
    .required('E-mail adres is verplicht'),
  postcode: Yup.string().required('Postcode is verplicht'),
  phoneNumber: Yup.string().optional(),
});

const DierSchema = Yup.object().shape({
  naam: Yup.string().required('Naam huisdier is verplicht'),
  type: Yup.string().required('Type huisdier is verplicht'),
  leeftijd: Yup.string().when('type', {
    is: (type) => type != '5',
    then: Yup.string().required('Leeftijd huisdier is verplicht'),
    otherwise: Yup.string().optional(),
  }),
  andere: Yup.string().when('type', {
    is: '5',
    then: Yup.string().required('Een ander type kiezen is verplicht'),
    otherwise: Yup.string().optional(),
  }),
  opmerking: Yup.string().optional(),
});

const UnsavedText = styled.div`
  line-height: 1.5em;
  text-align: center;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FormContentDier = ({ tll, onDierDelete, dierindex, isNew }) => {
  const handleDierDelete = React.useCallback(() => {
    onDierDelete?.(isNew);
  }, []);
  const { types, leeftijds, andereTypes } = tll;
  const typeValues = types
    ?.filter((type) => type.omschrijving !== 'Andere')
    .map((type) => ({ id: type.id, value: type.omschrijving }));
  const andereTypeValues = andereTypes?.map((type) => ({
    id: type.id,
    value: type.omschrijving,
  }));
  const leeftijdValues = leeftijds?.map((leeftijd) => ({
    id: leeftijd.id,
    value: leeftijd.omschrijving,
  }));
  const { submitForm, values, dirty, resetForm, errors } = useFormikContext();
  const isHondOfKat = React.useMemo(() => {
    return ['1', '2', '3', '4'].includes(values.type);
  }, [values.type]);

  return (
    <Form>
      <TextInput name="naam" label="Naam" required />
      <RadioButtonsWithOther
        id={`dier${dierindex}`}
        name="type"
        label="Type"
        required
        values={typeValues}
        otherValues={andereTypeValues}
        other={{ id: '5', value: null }}
      />
      {isHondOfKat && (
        <>
          <RadioButtons
            id={`dier${dierindex}`}
            name="leeftijd"
            label="Leeftijd"
            required={isHondOfKat}
            values={leeftijdValues}
          />
          <ThreeWaySwitch
            name="papersChecked"
            label="papieren in orde?"
            type="checkPapers"
            tooltips={{
              true: 'BOEKJE OK',
              null: 'BOEKJE NOG NIET GECONTROLEERD',
              false: 'BOEKJE NIET OK',
            }}
          />
        </>
      )}
      <TextInput name="opmerking" label="Opmerking" multiline />
      <Row>
        <Button
          primary
          onClick={submitForm}
          disabled={Object.keys(errors).length > 0}
          disabledReason="Er zijn fouten in het formulier, kijk alle velden na">
          <i className="far fa-save"></i>Dier Opslaan
        </Button>
        <Button secondary onClick={handleDierDelete}>
          <i className="far fa-trash-alt"></i>Dier Verwijderen
        </Button>
      </Row>
      <RouteLeavingGuard
        when={dirty}
        warn
        title="Opgelet: wijzigingen niet opgeslagen"
        onConfirm={resetForm}>
        <UnsavedText>
          Niet-opgeslagen wijzigingen zullen verloren gaan.
          <br />
          Wil je alsnog de pagina verlaten?
        </UnsavedText>
      </RouteLeavingGuard>
    </Form>
  );
};

export const DierBox = ({
  dier,
  dierindex,
  tll,
  setLoading,
  baasjeId,
  setDeleting,
  refetchLid,
  onSaved,
}) => {
  const [updateDier, { loading: loadingUpdateDier }] = useMutation(
    UPDATE_DIER,
    {
      onCompleted: () => {
        refetchLid();
        onSaved();
      },
    },
  );
  const [createDier] = useMutation(CREATE_DIER, {
    onCompleted: () => {
      onSaved();
      refetchLid();
    },
  });

  const handleDierSubmit = React.useCallback(
    (values) => {
      if (!dier.isNew) {
        // dier bestaat al in DB
        updateDier({
          variables: {
            id: dier.id,
            data: {
              ...values,
              andere: values.type === '5' ? values.andere : null,
            },
          },
        });
      } else {
        // dier bestaat nog niet in DB
        createDier({
          variables: {
            data: {
              ...values,
              andere: values.type === '5' ? values.andere : null,
              baasje: baasjeId,
            },
          },
        });
      }
    },
    [dier, dierindex, setLoading],
  );

  const handleDierDelete = React.useCallback(() => {
    setDeleting({
      descr: `dier ${dierindex + 1} ${dier.naam ? ': ' + dier.naam : ''}`,
      deleteID: dier.id,
      isNewDier: dier.isNew,
    });
  }, [dier.id, setDeleting, dierindex, dier.naam]);

  const initialValues = {
    naam: dier.naam,
    type: dier.type?.id,
    leeftijd: dier.leeftijd?.id,
    andere: dier.andere || undefined,
    opmerking: dier.opmerking || '',
    papersChecked: dier.papersChecked,
  };

  return (
    <Box title={`Dier ${dierindex + 1}`}>
      {loadingUpdateDier && <LoadingDivModal />}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={DierSchema}
        onSubmit={handleDierSubmit}>
        <FormContentDier
          tll={tll}
          onDierDelete={handleDierDelete}
          dierindex={dierindex}
          isNew={dier.isNew}
        />
      </Formik>
    </Box>
  );
};

const FormContentLid = ({ locaties, everBeenData, handleLidDelete }) => {
  const locatieValues = locaties?.map((locatie) => ({
    id: locatie.id,
    value: locatie.Naam,
  }));
  const {
    submitForm,
    dirty,
    resetForm,
    errors,
    isValid,
    touched,
  } = useFormikContext();
  const isEveryErrorFieldTouched = React.useMemo(
    () => Object.keys(errors).every((key) => touched.hasOwnProperty(key)),
    [errors, touched],
  );

  return (
    <Form>
      <TextInput name="fname" label="Voornaam" required />
      <TextInput name="lname" label="Familienaam" required />
      <Checkbox name="actief" label="Actief" required />
      <TextInput name="address" label="Adres" required />
      <TextInput name="postcode" label="Postcode" required />
      <TextInput name="city" label="Gemeente" required />
      <TextInput name="phoneNumber" label="Telefoonnummer" />
      <TextInput name="email" label="E-mail adres" required />
      <TextInput name="waarborgPotten" label="Waarborg potten" />
      <TextInput name="sinds" label="Lid sinds" disabled />
      <TextInput name="situatie" label="Situatie" multiline required />
      <Checkbox name="bevestigd" label="Situatie bevestigd" required />
      <TextInput name="opmerking" label="Opmerking" multiline />
      <Select
        name="locatie"
        label="Komt naar bedeling te"
        values={locatieValues}
      />
      <HideableList label="Ooit al geweest?" items={everBeenData} />
      <Files name="bestanden" label="Bestanden" />
      <Buttons>
        <Button
          primary
          onClick={submitForm}
          disabled={!isValid && isEveryErrorFieldTouched}
          disabledReason="Er zijn fouten in het formulier, kijk alle velden na">
          <i className="far fa-save"></i>Lid Opslaan
        </Button>
        <Button secondary onClick={handleLidDelete}>
          <i className="far fa-trash-alt"></i>Lid Verwijderen
        </Button>
      </Buttons>
      <RouteLeavingGuard
        when={dirty}
        warn
        title="Opgelet: wijzigingen niet opgeslagen"
        onConfirm={resetForm}>
        <UnsavedText>
          Niet-opgeslagen wijzigingen zullen verloren gaan.
          <br />
          Wil je alsnog de pagina verlaten?
        </UnsavedText>
      </RouteLeavingGuard>
    </Form>
  );
};

const Lid = ({ match }) => {
  const [showSavedModal, setShowSavedModal] = useState(false);
  // const [files, setFiles] = useState([]);
  const { data: lid, loading: loadingLid, refetch: refetchLid } = useQuery(
    GET_LID,
    {
      variables: { id: match.params.id },
      // fetchPolicy: 'cache-and-network',
      onCompleted: () => {
        // setFiles(data.leden?.bestanden ?? []);
      },
    },
  );
  const { loading: loadingTLL, data: dataTLL } = useQuery(GET_TLL, {
    // pollInterval: 5000,
  });
  const [deleting, setDeleting] = React.useState(null);
  const lidData = lid?.leden;
  const [saveLid, { loading: loadingSaveLid }] = useMutation(UPDATE_LID, {
    onCompleted: () => {
      showSavedModalBriefly(setShowSavedModal);
    },
  });
  const initialValues = {
    fname: lidData?.fname ?? '',
    lname: lidData?.lname ?? '',
    address: lidData?.address ?? '',
    postcode: lidData?.postcode ?? '',
    phoneNumber: lidData?.phoneNumber ?? '',
    city: lidData?.city ?? '',
    email: lidData?.email ?? '',
    waarborgPotten: lidData?.waarborgPotten ?? '',
    situatie: lidData?.situatie ?? '',
    opmerking: lidData?.opmerking ?? '',
    actief: lidData?.actief ?? false,
    bevestigd: lidData?.bevestigd ?? false,
    locatie: lidData?.locatie.id,
    sinds: lidData?.sinds,
    bestanden: lidData?.bestanden,
  };

  const [uploadFile, { loading: loadingUploadFile }] = useMutation(
    UPLOAD_FILE_SINGLE,
  );

  const [deleteLid, { loading: loadingDeleteLid }] = useMutation(DELETE_LID, {
    variables: { id: match.params.id },
  });

  const handleSubmit = useCallback(
    async (values) => {
      const localFiles = values.bestanden.filter((f) => !f.url);
      const savedFiles = values.bestanden.filter((f) => f.url && !f.deleted);
      const newFileIds = await Promise.all(
        localFiles.map(
          async ({ newFileData }) =>
            (
              await uploadFile({
                variables: { file: newFileData },
              })
            ).data.upload.id,
        ),
      );

      const { bestanden: _, ...rest } = values;
      await saveLid({
        variables: {
          id: match.params.id,
          data: {
            ...rest,
            bestanden: [...savedFiles.map((file) => file.id), ...newFileIds],
          },
        },
      });
    },
    [saveLid, uploadFile],
  );

  const [localHuisdieren, setLocalHuisdieren] = React.useState(
    lidData?.huisdieren || [],
  );

  const handleAddLocalHuisdier = React.useCallback(() => {
    setLocalHuisdieren((prev) => [
      ...prev,
      {
        id: (Math.random() + 1).toString(36), // add random string for .map with key
        isNew: true,
      },
    ]);
  }, []);

  const unsavedAnimalAdded = React.useMemo(
    () =>
      JSON.stringify(lidData?.huisdieren) !== JSON.stringify(localHuisdieren),
    [localHuisdieren, lidData?.huisdieren],
  );

  const [deleteDier, { loading: loadingDeleteDier }] = useMutation(
    DELETE_DIER,
    {
      onCompleted: () => {
        refetchLid();
      },
    },
  );

  React.useEffect(() => {
    if (lidData?.huisdieren) setLocalHuisdieren(lidData?.huisdieren);
  }, [setLocalHuisdieren, lidData?.huisdieren]);

  const handleDelete = React.useCallback(async () => {
    if (deleting.isLid) {
      await deleteLid();
      window.location.href = '/leden';
      setDeleting(null);
      return;
    }
    if (!deleting.isNewDier) {
      // delete bestaand dier

      await deleteDier({ variables: { id: deleting.deleteID } });
      // lidData.huisdieren.splice(indexDier, 1);
    } else {
      // delete nieuw toegevoegd dier dat nog niet is opgeslagen naar server
      const indexDier = localHuisdieren.indexOf(
        localHuisdieren.find((x) => x?.id === deleting.deleteID),
      );
      const newLocalHuisdieren = [...localHuisdieren];
      newLocalHuisdieren.splice(indexDier, 1);
      setLocalHuisdieren(newLocalHuisdieren);
    }
    setDeleting(null);
  }, [deleting, lidData?.huisdieren, refetchLid]);

  const everBeenData = useMemo(
    () =>
      lidData?.aanwezigheden
        ?.filter((aanw) => aanw.aanwezig)
        .map((aanw) => {
          const datum = new Date(aanw.bedeling?.datum);

          const dateOptions = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          };
          return {
            id: aanw.bedeling?.id,
            value: (
              <NavLink to={'/bedelingen/view/' + aanw.bedeling?.id}>
                {datum.toLocaleDateString('nl-BE', dateOptions)}
              </NavLink>
            ),
          };
        }),
    [lidData?.aanwezigheden],
  );

  const dierBoxOnSaved = useCallback(() => {
    showSavedModalBriefly(setShowSavedModal);
  }, []);

  const handleLidDelete = useCallback(async () => {
    setDeleting({
      deleteID: match.params.id,
      isLid: true,
      descr: `HET VOLLEDIG LID`,
    });
  }, [match.params.id]);

  return (
    <Page>
      {(loadingLid ||
        loadingSaveLid ||
        loadingTLL ||
        loadingDeleteDier ||
        loadingDeleteLid ||
        loadingUploadFile) && <LoadingDivModal />}
      <Box top title="Lid">
        {lidData && dataTLL?.tll && (
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnMount
            validationSchema={LidSchema}>
            <FormContentLid
              locaties={dataTLL.tll.locaties}
              everBeenData={everBeenData}
              handleLidDelete={handleLidDelete}
            />
          </Formik>
        )}
      </Box>
      {dataTLL &&
        localHuisdieren.map((dier, dierindex) => (
          <DierBox
            key={dier.id}
            dier={dier}
            dierindex={dierindex}
            tll={dataTLL?.tll}
            baasjeId={lidData?.id}
            setDeleting={setDeleting}
            refetchLid={refetchLid}
            onSaved={dierBoxOnSaved}
          />
        ))}
      <StyledRow center>
        <Button primary onClick={handleAddLocalHuisdier}>
          <i className="far fa-plus-square"></i> Dier toevoegen
        </Button>
      </StyledRow>
      {deleting && (
        <DeletingDivModal
          handleDelete={handleDelete}
          deleting={deleting}
          setDeleting={setDeleting}
        />
      )}
      {showSavedModal && <SavedModal />}
      <RouteLeavingGuard
        when={unsavedAnimalAdded}
        warn
        title="Opgelet: wijzigingen niet opgeslagen">
        <UnsavedText>
          Niet-opgeslagen wijzigingen zullen verloren gaan.
          <br />
          Wil je alsnog de pagina verlaten?
        </UnsavedText>
      </RouteLeavingGuard>
    </Page>
  );
};

export default Lid;
