import React from 'react';
import { useField } from 'formik';
import styled, { css } from 'styled-components';

import { Error, Input, Label, Wrapper } from '.';

const commonStyles = css`
  min-width: 15rem;
  border-radius: 0.3rem;
  border: 1px solid grey;
  padding: 0.5rem;
`;

const errorStyle = css`
  border: 2px solid #e85a1e;
`;

const StyledInput = styled.input`
  ${commonStyles}
  ${({ error }) => (error ? errorStyle : '')}
  /* line-height: 2rem; */
  font-size: 1rem;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const StyledTextArea = styled.textarea`
  ${commonStyles}
  ${({ error }) => (error ? errorStyle : '')}
  line-height: 1.1rem;
  padding: 0.5rem;
  width: 100%;
  max-width: 40rem;
  min-height: 4rem;
  display: block;

  @media (max-width: 600px) {
    max-width: 100%;
  }
`;
const Info = styled.div``;

const TextInput = ({
  name,
  label,
  multiline,
  disabled,
  info,
  placeholder,
  error,
  onChange,
}) => {
  const [field, meta] = useField(name);
  const handleOnChange = React.useCallback((ev) => {
    onChange?.(ev);
    field.onChange(ev);
  }, []);
  return (
    <Wrapper>
      <Label htmlFor={name}>{label}</Label>
      <Input>
        {info && <Info>{info}</Info>}
        {multiline ? (
          <StyledTextArea
            error={meta.error && meta.touched}
            type="text"
            placeholder={placeholder || label}
            {...field}
            onChange={handleOnChange}
            disabled={disabled}></StyledTextArea>
        ) : (
          <StyledInput
            error={error || (meta.error && meta.touched)}
            type="text"
            placeholder={placeholder || label}
            {...field}
            onChange={handleOnChange}
            disabled={disabled}
          />
        )}
        {(error || (meta.error && meta.touched)) && (
          <Error>{error || meta.error}</Error>
        )}
      </Input>
    </Wrapper>
  );
};

export default TextInput;
