import * as React from 'react';
import { Prompt, useHistory } from 'react-router-dom';

import PopupModal from './PopupWarningModal';

const RouteLeavingGuard = ({ when, warn, title, children, onConfirm }) => {
  const [modalVisible, setModalVisible] = React.useState(false);
  const [lastLocation, setLastLocation] = React.useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = React.useState(false);
  const history = useHistory();

  const handleBlockedNavigation = React.useCallback(
    (nextLocation) => {
      if (!confirmedNavigation) {
        setModalVisible(true);
        setLastLocation(nextLocation);
        return false;
      }
      return true;
    },
    [confirmedNavigation],
  );

  const handleConfirmNavigationClick = React.useCallback(() => {
    onConfirm?.();
    setModalVisible(false);
    setConfirmedNavigation(true);
  }, [onConfirm]);

  React.useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      setConfirmedNavigation(false);
      // actually navigate to the previously blocked location
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, history, lastLocation]);

  React.useEffect(() => {
    // also block navigation when refreshing or closing the page, but this is with the default browser prompt instead of our custom prompt
    if (when) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [when]);

  return (
    <>
      {/* smart way of using Prompt from react-router-dom:
          when 'message' is a string, Prompt will show up, while navigation will be allowed if it is true.
          By setting message to false, the navigation will be blocked while the default Prompt modal does not show up either.
          This allows us to show our custom prompt */}
      <Prompt when={when} message={handleBlockedNavigation} />
      <PopupModal
        visible={modalVisible}
        setPopup={setModalVisible}
        onConfirmation={handleConfirmNavigationClick}
        warn={warn}
        title={title}>
        {children}
      </PopupModal>
    </>
  );
};
export default RouteLeavingGuard;
