import styled from 'styled-components';

const ContextMenu = styled.div`
  position: absolute;
  min-width: 12rem;
  background-color: #ddd;
  /* border: 1px solid #e85a1e; */
  z-index: 20;
  top: calc(100% + 1px);
  left: -1px;

  ul {
    padding: 0.25rem;
    margin: 0;
    list-style: none;
  }
  ul li {
    padding: 1rem 0.75rem;
    border: 1px dashed transparent;
  }
  /* hover */
  ul li:hover {
    cursor: pointer;
    background-color: #eee;
    border: 1px dashed #e85a1e;
    box-sizing: content-box;
  }
`;

export default ContextMenu;
